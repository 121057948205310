import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import Typography from "@mui/material/Typography";
import { DiaryEntry } from "model";
import styled from "styled-components";
import DiaryEntryDialogContentForScroll from "./DiaryEntryDialogContentForScroll";

const StyledImageListItem = styled(ImageListItem)`
  margin-bottom: 16px !important;
  width: calc(50% - 16px) !important;
  padding: 0 8px !important;

  .MuiImageListItemBar-root {
    position: relative;
    margin-top: 8px;
  }

  img {
    width: 100%;
    height: auto;
  }
`;

const StyledImageList = styled(ImageList)`
  display: flex !important;
  flex-wrap: wrap !important;
  padding: 0 8px;
  margin: 0 -8px !important;
  width: calc(100% + 16px);
`;

interface Props {
  diaryEntries: DiaryEntry[];
  scrollDialogOpen: boolean;
  setScrollDialogOpen: (newValue: boolean) => void;
  setOpenDiaryEntryDialogGuid: (newValue: string | null) => void;
  dialogKey: string;
}

export default function DiaryEntryDialogWithScroll({
  dialogKey,
  diaryEntries,
  scrollDialogOpen,
  setScrollDialogOpen,
  setOpenDiaryEntryDialogGuid,
}: Props) {
  if (!scrollDialogOpen) {
    return <div key={`diary-entry-dialog-${dialogKey}`}></div>;
  }

  return (
    <div key={`diary-entry-dialog-${dialogKey}`}>
      <Dialog
        disableEscapeKeyDown
        key={dialogKey}
        fullScreen={false}
        open={scrollDialogOpen}
        onClose={() => setScrollDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          <IconButton
            onClick={() => {
              setScrollDialogOpen(false);
            }}
          >
            <ArrowBackIosIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ overflowY: "auto", maxHeight: "70vh", width: "100%" }}>
            <StyledImageList>
              {diaryEntries.map((diaryEntry) => (
                <StyledImageListItem key={diaryEntry.guid}>
                  <DiaryEntryDialogContentForScroll
                    key={`content-for-scroll-${diaryEntry.guid}`}
                    diaryEntry={diaryEntry}
                    setScrollDialogOpen={setScrollDialogOpen}
                    setOpenDiaryEntryDialogGuid={setOpenDiaryEntryDialogGuid}
                  />
                  <ImageListItemBar
                    position="below"
                    title={
                      <Typography
                        component="span"
                        variant="body2"
                        display="block"
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          width: "100%",
                        }}
                      >
                        {diaryEntry.diaryEntryText}
                      </Typography>
                    }
                    subtitle={
                      <Typography variant="caption" component="span">
                        {new Date(diaryEntry.entryDate).toLocaleDateString()}
                      </Typography>
                    }
                  />
                </StyledImageListItem>
              ))}
            </StyledImageList>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
