import { FullScreenDialog } from "common/components";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

// Define a local type for hbspt
type HubSpot = {
  forms: {
    create: (options: {
      portalId: string;
      formId: string;
      region: string;
      target: string;
      onFormSubmit: () => void;
    }) => void;
  };
};

const SignupIntroDialog = () => {
  const history = useHistory();

  useEffect(() => {
    // Create a script element
    const script = document.createElement("script");
    script.src = "https://js-eu1.hsforms.net/forms/embed/v2.js";
    script.async = true; // Add async loading

    // Append the script to the document body
    document.body.appendChild(script);

    // Once the script is loaded, create the form
    script.onload = () => {
      const hbspt = (window as unknown as { hbspt: HubSpot }).hbspt;
      if (hbspt) {
        hbspt.forms.create({
          portalId: "144333193",
          formId: "0a42592c-3276-4ba4-ac8a-e43a7f19df49",
          region: "eu1",
          target: "#hubspotForm",
          onFormSubmit: () => {
            history.push("/signup/user-details");
            return false;
          },
        });
      }
    };

    const handleFormSubmit = (event: MessageEvent) => {
      if (
        event.data.type === "hsFormCallback" &&
        event.data.eventName === "onFormSubmit"
      ) {
        const email = event.data.data.find(
          (field: { name: string; value: string }) => field.name === "email"
        )?.value;
        // Store the email in local storage
        if (typeof email === "string") {
          window.localStorage.setItem("signupEmail", email);
        }
      }
    };

    window.addEventListener("message", handleFormSubmit);

    // Cleanup function to remove the script when the component unmounts
    return () => {
      document.body.removeChild(script);
      window.removeEventListener("message", handleFormSubmit);
    };
  }, []);

  return (
    <FullScreenDialog>
      <StyledDialogContent>
        <div id="hubspotForm" />
        {/* This is where the form will be embedded */}
      </StyledDialogContent>
    </FullScreenDialog>
  );
};

export default SignupIntroDialog;

const StyledDialogContent = styled.div`
  background-color: #f7e880;
  padding: 16px;
  height: 100%;
  width: 100%;
`;
