import { GlobalStyles } from "@mui/material";
import {
  StyledEngineProvider,
  ThemeProvider as StyledThemeProvider,
} from "@mui/material/styles";
import { useEffect, useRef, useState } from "react";
import {
  HashRouter,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import styled from "styled-components";

import { ConfirmProvider } from "material-ui-confirm";
import { IntlProvider } from "react-intl";
import { Provider } from "react-redux";

import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";

import { useAppSelector } from "model";
import store, { RootState } from "model/rootReducer";

import localeData from "common/locales";

import { useTokenRefresh } from "common/utils";
import NotificationProvider from "./NotificationProvider";
import RefreshProvider from "./RefreshProvider";

import { PrivateRoute } from "app";
import { MapView } from "features/mapLayers";
import { NewSignupDialog } from "features/signup";
import { MapRef } from "react-map-gl";
import Drawer from "./Drawer";
import LoginDialog from "./LoginDialog";

import { App as CapacitorApp } from "@capacitor/app";
import "@mui/material/styles/createPalette";
import Oauth2Screen from "features/signup/Oauth2RedirectScreen";
import SignupIntroDialog from "features/signup/SignupIntroDialog";
import PasswordForgotDialog from "./PasswordForgotDialog";
import PasswordResetDialog from "./PasswordResetDialog";

declare module "@mui/material/styles/createPalette" {
  // adding custom color to the theme
  interface Palette {
    carbon: PaletteColor;
    drawTool: PaletteColor;
  }
  interface PaletteOptions {
    carbon: PaletteColorOptions;
    drawTool: PaletteColorOptions;
  }
}

declare module "@mui/material" {
  interface ButtonPropsColorOverrides {
    carbon: true;
    drawTool: true;
  }

  interface ButtonGroupPropsColorOverrides {
    carbon: true;
    drawTool: true;
  }

  interface SvgIconPropsColorOverrides {
    carbon: true;
    drawTool: true;
  }
}

const spacing = (value: number) => `${8 * value}px`;

const { palette } = createTheme();
const { augmentColor } = palette;

const createColor = (mainColor: string) =>
  augmentColor({ color: { main: mainColor } });

const theme = createTheme({
  spacing,
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "1em",
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontSize: "0.875rem",
          lineHeight: 1.43,
          letterSpacing: "0.01071em",
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: `${spacing(1.5)} ${spacing(2.5)}!important`,
        },
      },
    },
  },
  palette: {
    mode: "light",
    primary: {
      main: "#309381",
    },
    secondary: {
      main: "#f50057",
    },
    error: {
      main: "#CD0303",
    },
    carbon: {
      main: "#7935bc",
    },
    drawTool: createColor("#e0e0e0"), // old main color
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

// New AppContent component to handle routing and back button
function AppContent() {
  const mapRef = useRef<MapRef>(null);
  const history = useHistory();
  const location = useLocation();
  const isLoggedIn = useAppSelector((state: RootState) => state.app.loggedIn);
  const currentFarmId = useAppSelector(
    (state: RootState) => state.farms.currentFarmId
  );

  useEffect(() => {
    const handleBackButton = async () => {
      // Check if there's an active touch event on the map
      const activeElement = document.activeElement;
      const isMapEvent = activeElement?.closest(
        '[data-source="map-container"]'
      );

      // Don't handle back button if it's a map interaction
      if (isMapEvent) {
        return;
      }

      try {
        if (
          !isLoggedIn &&
          (location.pathname === "/" || location.pathname === "/login")
        ) {
          // Only exit if user is not logged in and at root/login
          await CapacitorApp.exitApp();
        } else if (
          isLoggedIn &&
          location.pathname === "/initial-screen-select"
        ) {
          // If logged in and at farm view, exit app
          await CapacitorApp.exitApp();
        } else {
          // Otherwise, go back in history
          history.goBack();
        }
      } catch (error) {
        console.error("Error handling back button:", error);
      }
    };

    // Add back button listener for Android
    void CapacitorApp.addListener("backButton", handleBackButton);

    return () => {
      // Cleanup listener when component unmounts
      void CapacitorApp.removeAllListeners();
    };
  }, [history, location, isLoggedIn, currentFarmId]);

  return (
    <Root>
      <Switch>
        <Route path="/login" exact children={<LoginDialog />} />
        <Route path="/signup" children={<NewSignupDialog />} />
        <Route path="/signup-intro" children={<SignupIntroDialog />} />
        <PrivateRoute path="/oauth2" children={<Oauth2Screen />} />
        <Route
          path="/login/password-forgot"
          exact
          children={<PasswordForgotDialog />}
        />
        <Route
          path="/login/password-reset"
          exact
          children={<PasswordResetDialog />}
        />
        <PrivateRoute path="/" exact children={<LoginDialog />} />
      </Switch>
      <Drawer mapRef={mapRef} />
      <MapView mapRef={mapRef} />
    </Root>
  );
}

export default function App() {
  let locale = store.getState().app.locale;
  const [messages, setMessages] = useState(localeData["en"]);
  useTokenRefresh();

  const updateLocale = () => {
    locale = store.getState().app.locale;
    if (Object.keys(localeData).includes(locale)) {
      setMessages(localeData[locale]);
    } else {
      setMessages(localeData["en"]);
    }
  };
  store.subscribe(updateLocale);

  return (
    <Provider store={store}>
      <IntlProvider locale={locale} key={locale} messages={messages}>
        <HashRouter>
          <GlobalStyles styles={{ "*": { "touch-action": "pan-x pan-y" } }} />
          <CssBaseline />
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <StyledThemeProvider theme={theme}>
                <NotificationProvider>
                  <ConfirmProvider>
                    <RefreshProvider>
                      <AppContent />
                    </RefreshProvider>
                  </ConfirmProvider>
                </NotificationProvider>
              </StyledThemeProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </HashRouter>
      </IntlProvider>
    </Provider>
  );
}

const Root = styled.div`
  flex-grow: 1;
`;
