import { InputBase } from "@mui/material";

import { styled, Theme } from "@mui/material/styles";

const DropdownInput = styled(InputBase)(({ theme }: { theme: Theme }) => ({
  minWidth: `${theme.breakpoints.values.xs}px`,
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "&.Mui-error .MuiInputBase-input": {
    border: "1px solid",
    borderColor: theme.palette.error.main,
  },
  borderRadius: 4,
  position: "relative",
  backgroundColor: theme.palette.background.paper,
  border: "1px solid #ced4da",
  padding: "6px 26px 5px 12px",
  transition: theme.transitions.create(["border-color", "box-shadow"]),
  "&:focus": {
    borderRadius: 4,
    borderColor: "#80bdff",
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
  },
}));

export default DropdownInput;
