/* eslint-disable @typescript-eslint/restrict-plus-operands */
import InfoIcon from "@mui/icons-material/Info";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import {
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { default as MuiTooltip } from "@mui/material/Tooltip";
import { mixpanel } from "common/analytics";
import { useTranslation } from "common/locales";
import { format, startOfDay } from "date-fns";
import { throttle } from "lodash";
import {
  RootState,
  selectStockClassBirthingPeriodsForStockClass,
  StockClass,
  useAppSelector,
} from "model";
import { DefaultStockClasses } from "model/defaultStockClassesSlice";
import { StockClassWithBirthingPeriods } from "model/stockClassBirthingPeriodSlices";
import {
  FEED_ALLOCATION_TYPE,
  selectStockClassFeedAllocationsForStockClass,
} from "model/stockClassFeedAllocationSlice";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  CartesianGrid,
  Dot,
  Label,
  Legend,
  Line,
  LineChart,
  ReferenceArea,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { CategoricalChartState } from "recharts/types/chart/generateCategoricalChart";
import styled from "styled-components";
import { v4 } from "uuid";
import { StockClassFeedAllocationExtended } from "./utils";

interface Props {
  stockClass: StockClassWithBirthingPeriods;
  visible: boolean;
  updateTemporaryFeedAllocations: (
    stockClassGuid: string,
    feedAllocations: StockClassFeedAllocationExtended[]
  ) => void;

  updateRemovedFeedAllocationsGuids: (
    removedFeedAllocationsGuids: string[]
  ) => void;
  updateNewFeedAllocationsGuids: (newFeedAllocationsGuids: string[]) => void;
}

const EXTRA_X_AXIS_DATE_RANGE_MS = 4 * 24 * 3600 * 1000; // 4 days
const MIN_DURATION_BETWEEN_FEED_ALLOCATIONS = 24 * 3600 * 1000; // 1 day

const CHART_HEIGHT = 400; // total chart height px, always fixed
const MARGIN_TOP = 45; // y value where actual grid starts px

const CHART_GRID_HEIGHT = 320; // grid height px, always fixed
const CHART_DOMAIN_MAX_Y_PERCENT = 10; // max value shown on grid (%)

const MARGIN_LEFT = 80; // x value where actual grid starts px
const MARGIN_RIGHT = 30; // extra pixels after the grid

enum YAxisType {
  kg,
  percentBW,
}

// convert chart coordinate to value 0..1
function cyToPercentValue(cy: number, CHART_DOMAIN_MAX_Y: number) {
  const y = CHART_GRID_HEIGHT - cy + MARGIN_TOP;

  return (y * CHART_DOMAIN_MAX_Y) / CHART_GRID_HEIGHT;
}

function cxToDateMs(cx: number, totalChartWidth: number, domain: number[]) {
  const gridWidth = totalChartWidth - MARGIN_LEFT - MARGIN_RIGHT;
  const x = cx - MARGIN_LEFT; // pixel delta starting from 0

  return (x * (domain[1] - domain[0])) / gridWidth + domain[0];
}

const DAY_MS = 24 * 3600 * 1000;

function round(n: number, nearest = 0.05) {
  return Math.ceil(n / nearest) * nearest;
}

export default function StockClassFeedAllocationChart({
  stockClass,
  visible,
  updateTemporaryFeedAllocations,
  updateNewFeedAllocationsGuids,
  updateRemovedFeedAllocationsGuids,
}: Props) {
  const locale = useSelector((state: RootState) => state.app.locale);

  const birthingPeriods = useAppSelector((state) =>
    selectStockClassBirthingPeriodsForStockClass(state, stockClass.guid)
  );
  const CHART_DOMAIN_MAX_Y_KG =
    (stockClass.averageAnimalWeightKg * CHART_DOMAIN_MAX_Y_PERCENT) / 100; // max value shown on grid (kg)

  const { t } = useTranslation();
  const feedAllocations =
    useSelector((state: RootState) =>
      selectStockClassFeedAllocationsForStockClass(state, stockClass.guid)
    ) || null;

  const [removedFeedAllocationsGuids, setRemovedFeedAllocationsGuids] =
    useState<string[]>([]);

  const [newFeedAllocationsGuids, setNewFeedAllocationsGuids] = useState<
    string[]
  >([]);
  const [preparedData, setPreparedData] = useState<
    StockClassFeedAllocationExtended[]
  >([]);

  const [helpDialogOpen, setHelpDialogOpen] = useState(false);

  const initialState: {
    left: string | number;
    right: string | number;
    refAreaLeft: string;
    refAreaRight: string;
    top: "dataMax+1";
    bottom: "dataMin-1";
    animation: true;
  } = {
    left: 0,
    right: 0,
    refAreaLeft: "",
    refAreaRight: "",
    top: "dataMax+1",
    bottom: "dataMin-1",
    animation: true,
  };

  const [zoomState, setZoomState] = useState<typeof initialState>(initialState);

  const [yAxisType, setYAxisType] = useState<YAxisType>(YAxisType.percentBW);

  const defaultStockClasses: DefaultStockClasses = useAppSelector(
    (state) => state.defaultStockClasses.stockClasses
  );

  const selectedDefaultStockClass = useMemo(
    () =>
      defaultStockClasses.find(
        (d) => d.name === stockClass.defaultStockClassName
      ),
    [defaultStockClasses, stockClass]
  );

  const handleYAxisType = (
    event: React.MouseEvent<HTMLElement>,
    newYAxisType: YAxisType
  ) => {
    if (newYAxisType !== null) {
      setYAxisType(newYAxisType);
    }
  };

  useEffect(() => {
    if (!feedAllocations) {
      return;
    }
    feedAllocations.sort(
      (a, b) =>
        Date.parse(a.dateStart).valueOf() - Date.parse(b.dateStart).valueOf()
    );

    const newPreparedData = feedAllocations.map((fa) => ({
      ...fa,
      allocatedBodyWeightFractionPercent: fa.allocatedBodyWeightFraction * 100,
      dateStartStr: new Date(Date.parse(fa.dateStart)).toLocaleDateString(
        locale
      ),
      dateStartMs: Date.parse(fa.dateStart).valueOf(),
      allocatedBodyWeightFractionKg:
        stockClass.averageAnimalWeightKg * fa.allocatedBodyWeightFraction,
      allocatedBodyWeightFractionKgTotal:
        stockClass.averageAnimalWeightKg *
        stockClass.animalCount *
        fa.allocatedBodyWeightFraction,
    }));
    setPreparedData(newPreparedData);
    updateTemporaryFeedAllocations(stockClass.guid, newPreparedData);
  }, [feedAllocations, locale]);

  const dateFormatter = (date: number) => {
    return new Date(date).toLocaleDateString(locale);
  };

  const [selectedFeedAllocationGuid, setSelectedFeedAllocationGuid] = useState<
    string | undefined
  >();

  const domain = [0, 0];

  const containerRef = useRef<HTMLDivElement>(null);

  const onDrag = useCallback(
    throttle((chartState: CategoricalChartState) => {
      mixpanel.track("feed budgeting allocation graph interacted, drag item");
      // TODO: if active handle is more than 1 day from current point, we need to create a new one and not drag active handle!
      const guid = selectedFeedAllocationGuid;
      if (!guid || !chartState) {
        return;
      }
      if (!containerRef.current || !domain) {
        return;
      }

      const rect = containerRef.current.getBoundingClientRect();

      if (chartState.chartY) {
        const newPreparedData = preparedData.map((fa) => {
          if (fa.guid === guid) {
            const allocatedBodyWeightFractionValue = round(
              cyToPercentValue(
                chartState.chartY!,
                yAxisType === YAxisType.percentBW
                  ? CHART_DOMAIN_MAX_Y_PERCENT
                  : CHART_DOMAIN_MAX_Y_KG
              )
            );

            const allocatedBodyWeightFractionPercent =
              yAxisType === YAxisType.percentBW
                ? allocatedBodyWeightFractionValue
                : round(
                    (allocatedBodyWeightFractionValue * 100) /
                      stockClass.averageAnimalWeightKg
                  ); // convert from kg
            const dateStart = startOfDay(
              new Date(
                round(
                  cxToDateMs(chartState.chartX!, rect.width, [
                    zoomState.left as any,
                    zoomState.right as any,
                  ]),
                  DAY_MS
                )
              )
            );

            const nearestItem = preparedData.find(
              (p) =>
                p.guid !== guid &&
                Math.abs(p.dateStartMs - dateStart.valueOf()) <
                  MIN_DURATION_BETWEEN_FEED_ALLOCATIONS
            );

            if (nearestItem) {
              return fa;
            }

            return {
              ...fa,
              allocatedBodyWeightFraction:
                allocatedBodyWeightFractionPercent / 100,
              allocatedBodyWeightFractionPercent,
              dateStart: format(dateStart, "yyyy-MM-dd'T'HH:mm:ss'Z'"), // we don't care about timezone or exact time, just which date it is
              dateStartMs: dateStart.valueOf(),
              dateStartStr: dateFormatter(dateStart.valueOf()),
              allocatedBodyWeightFractionKg:
                (stockClass.averageAnimalWeightKg *
                  allocatedBodyWeightFractionPercent) /
                100,
              allocatedBodyWeightFractionKgTotal:
                (stockClass.averageAnimalWeightKg *
                  stockClass.animalCount *
                  allocatedBodyWeightFractionPercent) /
                100,
            };
          }
          return fa;
        });

        newPreparedData.sort(
          (a, b) =>
            Date.parse(a.dateStart).valueOf() -
            Date.parse(b.dateStart).valueOf()
        );

        setPreparedData(newPreparedData);
        updateTemporaryFeedAllocations(stockClass.guid, newPreparedData);
      }
    }, 10),
    [preparedData, selectedFeedAllocationGuid]
  );

  function removeFeedAllocation(
    removedFeedAllocation: StockClassFeedAllocationExtended
  ) {
    if (preparedData.length <= 2) {
      return;
    }

    mixpanel.track("feed budgeting allocation graph interacted, remove item");

    if (!newFeedAllocationsGuids.includes(removedFeedAllocation.guid)) {
      // only remove existing guids, do not remove not yet created
      setRemovedFeedAllocationsGuids([
        ...removedFeedAllocationsGuids,
        removedFeedAllocation.guid,
      ]);
      updateRemovedFeedAllocationsGuids([
        ...removedFeedAllocationsGuids,
        removedFeedAllocation.guid,
      ]);
    }

    setPreparedData(
      preparedData.filter((item) => item.guid !== removedFeedAllocation.guid)
    );
  }

  function createFeedAllocation(chartState: CategoricalChartState) {
    if (!containerRef.current || !domain || !chartState) {
      return;
    }

    mixpanel.track("feed budgeting allocation graph interacted, create item");

    const rect = containerRef.current.getBoundingClientRect();

    if (chartState.chartY) {
      const allocatedBodyWeightFractionValue = round(
        cyToPercentValue(
          chartState.chartY,
          yAxisType === YAxisType.percentBW
            ? CHART_DOMAIN_MAX_Y_PERCENT
            : CHART_DOMAIN_MAX_Y_KG
        )
      );

      const allocatedBodyWeightFractionPercent =
        yAxisType === YAxisType.percentBW
          ? allocatedBodyWeightFractionValue
          : round(
              (allocatedBodyWeightFractionValue * 100) /
                stockClass.averageAnimalWeightKg
            ); // convert from kg

      const dateStart = startOfDay(
        new Date(
          round(
            cxToDateMs(chartState.chartX!, rect.width, [
              zoomState.left as any,
              zoomState.right as any,
            ]),
            DAY_MS
          )
        )
      );

      const nearestItem = preparedData.find(
        (p) =>
          Math.abs(p.dateStartMs - dateStart.valueOf()) <
          MIN_DURATION_BETWEEN_FEED_ALLOCATIONS
      );

      if (nearestItem) {
        // do not create another feed allocation that is less than 1 day apart from any other one
        return;
      }
      const newGuid = v4();

      const newPreparedData = [
        ...preparedData,
        {
          guid: newGuid,
          allocationType: FEED_ALLOCATION_TYPE.STOCK_CLASS,
          stockClassGuid: stockClass.guid,
          allocatedBodyWeightFraction: allocatedBodyWeightFractionPercent / 100,
          allocatedBodyWeightFractionPercent,
          dateStart: format(dateStart, "yyyy-MM-dd'T'HH:mm:ss'Z'"), // we don't care about timezone or exact time, just which date it is
          dateStartMs: dateStart.valueOf(),
          dateStartStr: dateFormatter(dateStart.valueOf()),
          allocatedBodyWeightFractionKg:
            (stockClass.averageAnimalWeightKg *
              allocatedBodyWeightFractionPercent) /
            100,
          allocatedBodyWeightFractionKgTotal:
            (stockClass.averageAnimalWeightKg *
              stockClass.animalCount *
              allocatedBodyWeightFractionPercent) /
            100,
        },
      ];

      newPreparedData.sort(
        (a, b) =>
          Date.parse(a.dateStart).valueOf() - Date.parse(b.dateStart).valueOf()
      );
      setPreparedData(newPreparedData);
      updateTemporaryFeedAllocations(stockClass.guid, newPreparedData);
      setNewFeedAllocationsGuids([...newFeedAllocationsGuids, newGuid]);
      updateNewFeedAllocationsGuids([...newFeedAllocationsGuids, newGuid]);
    }
  }

  const zoom = () => {
    let { refAreaLeft, refAreaRight } = zoomState;

    if (refAreaLeft === refAreaRight || refAreaRight === "") {
      setZoomState({
        ...zoomState,
        refAreaLeft: "",
        refAreaRight: "",
      });

      return;
    }

    mixpanel.track("feed budgeting allocation graph interacted, zoom in");

    // xAxis domain
    if (refAreaLeft && refAreaRight && refAreaLeft > refAreaRight) {
      [refAreaLeft, refAreaRight] = [refAreaRight, refAreaLeft];
    }

    setZoomState({
      ...zoomState,
      left: parseInt(refAreaLeft, 10),
      right: parseInt(refAreaRight, 10),
      refAreaLeft: "",
      refAreaRight: "",
    });
  };

  const zoomOut = () => {
    if (preparedData.length > 0) {
      mixpanel.track("feed budgeting allocation graph interacted, zoom out");
      setZoomState({
        ...zoomState,
        left:
          Math.min(...preparedData.map((d) => d.dateStartMs)) -
          EXTRA_X_AXIS_DATE_RANGE_MS,
        right:
          Math.max(...preparedData.map((d) => d.dateStartMs)) +
          EXTRA_X_AXIS_DATE_RANGE_MS,
      });
    }
  };

  useEffect(() => {
    if (preparedData.length > 0) {
      setZoomState({
        ...zoomState,
        left:
          Math.min(...preparedData.map((d) => d.dateStartMs)) -
          EXTRA_X_AXIS_DATE_RANGE_MS,
        right:
          Math.max(...preparedData.map((d) => d.dateStartMs)) +
          EXTRA_X_AXIS_DATE_RANGE_MS,
      });
    }
  }, [feedAllocations, preparedData]);

  const { left, right, refAreaLeft, refAreaRight, top, bottom } = zoomState;

  const chartDomain = [
    parseInt(left as string, 10),
    parseInt(right as string, 10),
  ];

  if (!visible) {
    return null;
  }

  return (
    <div>
      <div>
        <IconButton
          edge="end"
          aria-haspopup="true"
          onClick={zoomOut}
          size="large"
          // TODO: zoom disabled for now
          style={{ marginLeft: "50px", display: "none" }}
        >
          <ZoomOutIcon />
        </IconButton>
        <ToggleButtonGroup
          value={yAxisType}
          exclusive
          onChange={handleYAxisType}
          style={{ marginLeft: "20px" }}
        >
          <ToggleButton value={YAxisType.kg}>
            {t("feedBudgetPlanner.stockClassFeedAllocationChart.kg")}
          </ToggleButton>
          <ToggleButton value={YAxisType.percentBW}>
            {t("feedBudgetPlanner.stockClassFeedAllocationChart.bw")}
          </ToggleButton>
        </ToggleButtonGroup>
        <IconButton
          edge="end"
          aria-haspopup="true"
          onClick={() => {
            mixpanel.track("feed budgeting i icon clicked");
            setHelpDialogOpen(true);
          }}
          size="large"
          style={{ marginLeft: "20px" }}
        >
          <InfoIcon />
        </IconButton>
      </div>
      <div style={{ width: "100%", height: "100%", userSelect: "none" }}>
        <div style={{ width: "100%", height: "100%" }} ref={containerRef}>
          <StyledContainer width="100%" height="100%">
            <LineChart
              width={730}
              height={CHART_HEIGHT}
              data={preparedData}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              onMouseDown={(chartState, e) => {
                // TODO: zoom disabled for now
                // if (chartState?.activeLabel && !selectedFeedAllocationGuid) {
                //   setZoomState({
                //     ...zoomState,
                //     refAreaLeft: chartState.activeLabel!,
                //   });
                // }

                if (!chartState?.activePayload?.[0].payload.guid) {
                  setSelectedFeedAllocationGuid(undefined);
                }
              }}
              onClick={(chartState, e) => {
                if (!zoomState.refAreaLeft) {
                  createFeedAllocation(chartState);
                }
              }}
              onMouseUp={(d, e) => {
                setSelectedFeedAllocationGuid(undefined);
                // TODO: zoom disabled for now
                // zoom();
              }}
              onMouseMove={(e: any) => {
                // TODO: zoom disabled for now
                // if (!selectedFeedAllocationGuid && zoomState.refAreaLeft) {
                //   setZoomState({ ...zoomState, refAreaRight: e.activeLabel });
                // }
                onDrag(e);
              }}
            >
              <defs>
                <linearGradient id="colorZv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#AFE47F" stopOpacity={0.2} />
                  <stop offset="95%" stopColor="#AFE47F" stopOpacity={0.9} />
                </linearGradient>
              </defs>
              <CartesianGrid strokeDasharray="5 5" />
              <XAxis
                allowDataOverflow
                dataKey="dateStartMs"
                scale="time"
                tickFormatter={dateFormatter}
                type="number"
                domain={chartDomain}
              />
              <YAxis
                allowDataOverflow
                domain={[
                  0,
                  yAxisType === YAxisType.percentBW
                    ? CHART_DOMAIN_MAX_Y_PERCENT
                    : CHART_DOMAIN_MAX_Y_KG,
                ]}
              />
              <Tooltip
                content={(props: any) => (
                  <CustomTooltip {...props} yAxisType={yAxisType} />
                )}
              />
              <ReferenceLine
                x={startOfDay(new Date()).valueOf()}
                stroke="#F7E77F"
                strokeWidth={2}
                label={
                  <Label
                    position="insideTopLeft"
                    value={t("feedBudgetPlanner.referenceLabel.now.label")}
                  />
                }
              />
              <Legend
                verticalAlign="top"
                wrapperStyle={{ lineHeight: "40px" }}
              />
              {birthingPeriods &&
                birthingPeriods.map((bp) => (
                  <ReferenceArea
                    x1={new Date(bp.dateStart).valueOf()}
                    x2={new Date(bp.dateEnd).valueOf()}
                    fill="url(#colorZv)"
                    stroke="#AFE47F"
                    strokeOpacity={0.9}
                    strokeDasharray="5 5"
                    label={
                      <Label
                        position="insideBottomLeft"
                        value={t(
                          "feedBudgetPlanner.referenceArea.birthingPeriod.label",
                          {
                            start: new Date(bp.dateStart).toLocaleDateString(
                              locale
                            ),
                            end: new Date(bp.dateEnd).toLocaleDateString(
                              locale
                            ),
                          }
                        )}
                      />
                    }
                  />
                ))}
              <Line
                animationDuration={10}
                type="monotone"
                dataKey={
                  yAxisType === YAxisType.percentBW
                    ? "allocatedBodyWeightFractionPercent"
                    : "allocatedBodyWeightFractionKg"
                }
                stroke="#8a5ae2"
                strokeWidth={2}
                dot={(props) => <InactiveDot {...props} />}
                name={t(
                  yAxisType === YAxisType.percentBW
                    ? "feedbBudgetPlanner.feedAllocation.stockClassFeedAllocationLineNameBW"
                    : "feedbBudgetPlanner.feedAllocation.stockClassFeedAllocationLineNameKg",
                  {
                    stockClassName: stockClass.name,
                  }
                )}
                activeDot={(props) => (
                  <ActiveDot
                    {...props}
                    removeFeedAllocation={removeFeedAllocation}
                    setSelectedFeedAllocationGuid={
                      setSelectedFeedAllocationGuid
                    }
                  />
                )}
              />

              {refAreaLeft && refAreaRight ? (
                <ReferenceArea
                  yAxisId="0"
                  x1={refAreaLeft}
                  x2={refAreaRight}
                  strokeOpacity={0.3}
                />
              ) : null}

              {selectedDefaultStockClass?.defaultStockClassDemandTemplates.map(
                (template) => {
                  return (
                    <ReferenceLine
                      y={
                        yAxisType === YAxisType.percentBW
                          ? (
                              template.allocatedBodyWeightFraction * 100
                            ).toFixed(2)
                          : (
                              template.allocatedBodyWeightFraction *
                              stockClass.averageAnimalWeightKg
                            ).toFixed(2)
                      }
                      stroke="#8a5ae2"
                      strokeDasharray="5 5"
                      isFront
                      label={
                        <Label
                          position="left"
                          content={(props) => (
                            <ReferenceLineHelper
                              {...(props as any)}
                              title={t(`defaultFeeding.${template.name}`)}
                              allocatedBodyWeightFraction={
                                template.allocatedBodyWeightFraction
                              }
                            />
                          )}
                        />
                      }
                    />
                  );
                }
              )}
            </LineChart>
          </StyledContainer>
        </div>
      </div>
      {stockClass && (
        <HelpDialog
          open={helpDialogOpen}
          stockClass={stockClass}
          onClose={() => setHelpDialogOpen(false)}
        />
      )}
    </div>
  );
}

export const StyledContainer = styled(ResponsiveContainer)`
  min-height: ${CHART_HEIGHT}px;
`;

const ReferenceLineHelper = ({
  viewBox: { x, y },
  title,
  allocatedBodyWeightFraction,
}: {
  viewBox: { x: number; y: number };
  title: string;
  allocatedBodyWeightFraction: number;
}) => {
  const d = 24;

  const transform = `translate(${x - 2 * d} ${y - d / 2})`;

  return (
    <MuiTooltip
      title={`${title}: ${(allocatedBodyWeightFraction * 100).toPrecision(2)}%`}
    >
      <g transform={transform}>
        <svg
          aria-hidden="true"
          viewBox={`0 0 ${d} ${d}`}
          data-testid="ArrowRightIcon"
          aria-label="fontSize small"
          height={d}
          width={d}
          fill="#8a5ae2"
        >
          <path d="m10 17 5-5-5-5v10z"></path>
        </svg>
      </g>
    </MuiTooltip>
  );
};

interface CustomizedDotProps {
  cx: number;
  cy: number;
  r: number;
  x: number;
  y: number;
  dot: any;
  stroke: string;
  fill: string;
  strokeWidth: string;
  payload: StockClassFeedAllocationExtended;
  value: number;
  removeFeedAllocation: (payload: StockClassFeedAllocationExtended) => void;
  setSelectedFeedAllocationGuid: (guid: string) => void;
}

const InactiveDot = (props: CustomizedDotProps) => {
  const { cx, cy, stroke, payload, value, fill, strokeWidth, r, x, y } = props;

  return (
    <Dot
      cx={cx}
      cy={cy}
      r={r}
      stroke={stroke}
      fill={"green"}
      strokeWidth={strokeWidth}
    ></Dot>
  );
};

const ActiveDot = (props: CustomizedDotProps) => {
  const {
    cx,
    cy,
    stroke,
    payload,
    value,
    fill,
    strokeWidth,
    r,
    x,
    y,
    removeFeedAllocation,
    setSelectedFeedAllocationGuid,
  } = props;

  return (
    <svg
      cx={cx - 12}
      cy={cy - 12}
      r={r * 3}
      x={cx - 12}
      y={cy - 12}
      width={"24"}
      height={"24"}
      focusable="false"
      viewBox="0 0 24 24"
      className="recharts-dot"
      onMouseDown={(e) => {
        setSelectedFeedAllocationGuid(payload.guid);
      }}
      onDoubleClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        removeFeedAllocation(payload);
      }}
      fill={"green"}
      stroke={stroke}
    >
      <path d="M15.54 5.54 13.77 7.3 12 5.54 10.23 7.3 8.46 5.54 12 2zm2.92 10-1.76-1.77L18.46 12l-1.76-1.77 1.76-1.77L22 12zm-10 2.92 1.77-1.76L12 18.46l1.77-1.76 1.77 1.76L12 22zm-2.92-10 1.76 1.77L5.54 12l1.76 1.77-1.76 1.77L2 12z"></path>
      <circle cx="12" cy="12" r="5"></circle>
    </svg>
  );
};

type CustomTooltipProps = {
  payload: Array<{
    payload: StockClassFeedAllocationExtended;
  }>;
  active: boolean;
  label: string;
  yAxisType: YAxisType;
};

function CustomTooltip({
  active,
  label,
  payload,
  yAxisType,
}: CustomTooltipProps) {
  const { t } = useTranslation();
  const locale = useSelector((state: RootState) => state.app.locale);
  const dateFormatter = (date: number) => {
    return new Date(date).toLocaleDateString(locale);
  };
  if (!payload?.[0]?.payload) return null;
  const feedAllocation = payload[0].payload;

  if (active) {
    return (
      <Card elevation={3}>
        <CardContent>
          <Typography variant="h6">
            {dateFormatter(feedAllocation.dateStartMs)}
          </Typography>
          <Typography>
            {t(
              "feedbBudgetPlanner.feedAllocation.allocatedBodyWeightFractionPercent",
              {
                allocatedBodyWeightFractionPercent:
                  payload[0].payload.allocatedBodyWeightFractionPercent.toFixed(
                    2
                  ),
              }
            )}
          </Typography>
          <Typography>
            {t(
              "feedbBudgetPlanner.feedAllocation.allocatedBodyWeightFractionKg",
              {
                allocatedBodyWeightFractionKg:
                  payload[0].payload.allocatedBodyWeightFractionKg.toFixed(2),
              }
            )}
          </Typography>
        </CardContent>
      </Card>
    );
  }
  return null;
}

function HelpDialog({
  onClose,
  open,
  stockClass,
}: {
  onClose: () => void;
  open: boolean;
  stockClass: StockClass;
}) {
  const { t } = useTranslation();
  const defaultStockClasses: DefaultStockClasses = useAppSelector(
    (state) => state.defaultStockClasses.stockClasses
  );

  const selectedDefaultStockClass = useMemo(
    () =>
      defaultStockClasses.find(
        (d) => d.name === stockClass.defaultStockClassName
      ),
    [defaultStockClasses, stockClass]
  );
  const templates = [
    ...(selectedDefaultStockClass?.defaultStockClassDemandTemplates || []),
  ];
  if (templates) {
    templates.sort((a, b) => a.position - b.position);
  }
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>{t("feedBudgetPlanner.helpDialog.title")}</DialogTitle>
      <TableContainer>
        <Table size="medium">
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: 600 }}>Stock class</TableCell>
              <TableCell align="left" style={{ fontWeight: 600 }}>
                {t("feedBudgetPlanner.helpDialog.feeding")}
              </TableCell>
              <TableCell align="left" style={{ fontWeight: 600 }}>
                {t("feedBudgetPlanner.helpDialog.bw")}
              </TableCell>
              <TableCell align="left" style={{ fontWeight: 600 }}>
                {t("feedBudgetPlanner.helpDialog.kg")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {templates?.map((template) => {
              const stockClassName = t(
                `defaultStockClass.${template.stockClassStageName}`
              );
              const feedingName = t(`defaultFeeding.${template.name}`);
              return (
                <TableRow
                  key={template.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {stockClassName}
                  </TableCell>
                  <TableCell align="left">{feedingName}</TableCell>
                  <TableCell align="left">
                    {(template.allocatedBodyWeightFraction * 100).toFixed(2)}
                  </TableCell>
                  <TableCell align="left">
                    {(
                      template.allocatedBodyWeightFraction *
                      stockClass.averageAnimalWeightKg
                    ).toFixed(2)}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Dialog>
  );
}
