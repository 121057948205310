import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";
import { API } from "common/utils";
import { subYears } from "date-fns";
import { RootState } from "model";
const weekMs = 7 * 24 * 3600 * 1000;

export enum EventType {
  GrazingEvent = "GrazingEvent",
  PartialGrazingEvent = "PartialGrazingEvent",
  SilageEvent = "SilageEvent",
  None = "None",
}

export type InputKind = "user" | "machine";
export type InputVerificationStatus = "Proposed" | "Accepted" | "Rejected";
export interface Event {
  eventType: EventType;
  dateTimeIn?: Date;
  dateTimeOut?: Date;
  dateTimeCut?: Date;
  preEventCover?: number;
  postEventCover?: number;
}
export interface ManualDMInputItem {
  dryMatterValue: number;
  newDryMatterValue?: number;
  field: string;
  fieldName?: string;
  dateTimeMeasurementStart?: Date;
  dateTimeMeasurementEnd?: Date;
  event: Event;
  kind: InputKind;
  verificationStatus: InputVerificationStatus;
  version?: string;
}
export interface ManualDMInput {
  guid: string;
  dryMatterMachineEstimate?: number;
  manualDryMatterInput: ManualDMInputItem;
}

export interface EventResponse {
  eventType: EventType;
  dateTimeIn?: string;
  dateTimeOut?: string;
  dateTimeCut?: string;
  preEventCover?: number;
  postEventCover?: number;
}
export interface ManualDMInputResponseItem {
  dryMatterValue?: number;
  field: string;
  dateTimeMeasurementStart: string;
  dateTimeMeasurementEnd: string;
  event: EventResponse;
  kind: InputKind;
  verificationStatus: InputVerificationStatus;
  version?: string;
}
export interface ManualDMInputResponse {
  guid: string; // TODO: remove, it's not used by the backend
  dryMatterMachineEstimate?: number;
  manualDryMatterInput: ManualDMInputResponseItem;
}

export interface BulkResponse {
  data: Array<ManualDMInputResponse>;
  meta?: {
    nextCursor?: string | null;
    nextPage?: string | null;
  };
}

class ManualInputAPI extends API<ManualDMInputResponse> {
  async getManyBulk(
    ids: Array<string>,
    includeNullDryMatter = true
  ): Promise<Array<ManualDMInputResponse>> {
    // TODO: implement pagination

    const iterate = async (nextCursor?: string) => {
      const res = await fetch(
        `${this.baseUrl}${this.entity}/bulk?guids=${ids.join(
          ","
        )}&include_null_dry_matter=${String(
          includeNullDryMatter
        )}&next_cursor=${nextCursor}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("jwt") || ""}`,
            "Content-Type": "application/json",
          },
        }
      );

      return (await res.json()) as BulkResponse;
    };

    const json = await iterate();
    const data = json.data;

    let nextCursor = json.meta?.nextCursor;
    while (nextCursor) {
      const json = await iterate(nextCursor);
      nextCursor = json.meta?.nextCursor;
      data.push(...json.data);
    }

    return data;
  }
}

const manualInputApi = new ManualInputAPI("manualDryMatterInput");

const manualInputAdapter = createEntityAdapter<ManualDMInputResponse>({
  selectId: (manualInput) => manualInput.guid,
});

const { selectAll } = manualInputAdapter.getSelectors(
  (state: RootState) => state.readings
);

const selectItemId = (state: RootState, itemId: string) => itemId;
const selectItemIds = (state: RootState, itemIds: string[]) => itemIds;

export const selectAllEventsForField = createSelector(
  [selectAll, selectItemId],
  (readings, fieldId) =>
    readings.reduce((acc: Array<ManualDMInputResponse>, curr) => {
      if (
        curr.manualDryMatterInput.field === fieldId &&
        curr.manualDryMatterInput.verificationStatus !== "Rejected"
      ) {
        acc.push(curr);
      }
      return acc;
    }, [])
);

export const selectAllEventsForFields = (fieldGuids: string[]) =>
  createSelector(selectAll, (readings) =>
    readings.reduce((acc: Map<string, ManualDMInputResponse[]>, curr) => {
      if (
        fieldGuids.includes(curr.manualDryMatterInput.field) &&
        curr.manualDryMatterInput.verificationStatus !== "Rejected"
      ) {
        const results = acc.get(curr.manualDryMatterInput.field)!;
        results?.push(curr);
        acc.set(curr.manualDryMatterInput.field, results);
      }
      return acc;
    }, new Map(fieldGuids.map((guid) => [guid, []])))
  );

const getLatestCurrentDate = (
  manualDMInputResponse: ManualDMInputResponse
): Date | undefined => {
  if (manualDMInputResponse.manualDryMatterInput.event) {
    if (
      manualDMInputResponse.manualDryMatterInput.event.eventType ===
        EventType.GrazingEvent ||
      manualDMInputResponse.manualDryMatterInput.event.eventType ===
        EventType.PartialGrazingEvent
    ) {
      if (manualDMInputResponse.manualDryMatterInput.event.dateTimeOut) {
        return new Date(
          manualDMInputResponse.manualDryMatterInput.event.dateTimeOut
        );
      }
      if (manualDMInputResponse.manualDryMatterInput.event.dateTimeIn) {
        return new Date(
          manualDMInputResponse.manualDryMatterInput.event.dateTimeIn
        );
      }
    }
  }
  return;
};

export const selectLatestManualReadingDate = (fieldGuids: string[]) =>
  createSelector(selectAll, (readings) => {
    let latestmanualReadingDate: Date | undefined = undefined;
    readings.forEach((reading: ManualDMInputResponse) => {
      if (
        fieldGuids.includes(reading.manualDryMatterInput.field) &&
        reading.manualDryMatterInput.verificationStatus !== "Rejected"
      ) {
        if (reading.manualDryMatterInput.event) {
          if (
            reading.manualDryMatterInput.event.eventType ===
              EventType.GrazingEvent ||
            reading.manualDryMatterInput.event.eventType ===
              EventType.PartialGrazingEvent
          ) {
            const currentLatestDate: Date | undefined =
              getLatestCurrentDate(reading);
            if (currentLatestDate) {
              if (!latestmanualReadingDate) {
                latestmanualReadingDate = currentLatestDate;
              }
              if (latestmanualReadingDate < currentLatestDate) {
                latestmanualReadingDate = currentLatestDate;
              }
            }
          }
        }
      }
    });
    return latestmanualReadingDate;
  });

export const selectAllProposedEventsForOneWeekSorted = createSelector(
  [selectAll],
  (readings) => {
    const timestampOneWeekAgo = new Date().getTime() - weekMs;
    const validEventTypes = [
      EventType.GrazingEvent,
      EventType.PartialGrazingEvent,
      EventType.SilageEvent,
    ];
    const filteredEvents = readings.filter((reading) => {
      const { event, verificationStatus } = reading.manualDryMatterInput;

      if (validEventTypes.indexOf(event.eventType) === -1) {
        return false;
      }

      if (verificationStatus !== "Proposed") {
        return false;
      }

      if (
        event.dateTimeIn &&
        Date.parse(event.dateTimeIn) < timestampOneWeekAgo
      ) {
        return false;
      }

      if (
        event.dateTimeCut &&
        Date.parse(event.dateTimeCut) < timestampOneWeekAgo
      ) {
        return false;
      }

      if (!isValidEvent(reading)) {
        return false;
      }

      return true;
    });

    filteredEvents.sort((a, b) => {
      const eventA = a.manualDryMatterInput.event;
      const eventB = b.manualDryMatterInput.event;
      if (eventA.dateTimeIn && eventB.dateTimeIn) {
        return Date.parse(eventB.dateTimeIn) - Date.parse(eventA.dateTimeIn);
      } else if (eventA.dateTimeIn && eventB.dateTimeCut) {
        return Date.parse(eventB.dateTimeCut) - Date.parse(eventA.dateTimeIn);
      } else if (eventB.dateTimeIn && eventA.dateTimeCut) {
        return Date.parse(eventB.dateTimeIn) - Date.parse(eventA.dateTimeCut);
      } else {
        return -1;
      }
    });

    return filteredEvents;
  }
);

export const getRecentProposedEventsForField = createSelector(
  selectAllProposedEventsForOneWeekSorted,
  (events) => (fieldId: string) =>
    events.filter((event) => event.manualDryMatterInput.field === fieldId)
);

export function isValidEvent(reading: ManualDMInputResponse): boolean {
  switch (reading?.manualDryMatterInput?.event?.eventType) {
    case EventType.GrazingEvent:
    case EventType.PartialGrazingEvent:
      return (
        !!reading?.manualDryMatterInput?.event?.dateTimeIn &&
        !!reading?.manualDryMatterInput?.event?.dateTimeOut
      );
    case EventType.SilageEvent:
      return !!reading?.manualDryMatterInput?.event?.dateTimeCut;
  }
  return true;
}

export const getReadingsForField = createAsyncThunk(
  "readings/getAllForField",
  async (fieldId: string, { dispatch }) => {
    const time1YearAgo = subYears(new Date(), 1);

    const ids = await manualInputApi.getIds(
      new URLSearchParams({
        field: fieldId,
        included_event_types:
          "GrazingEvent,SilageEvent,PartialGrazingEvent,AnimalMoveInEvent,AnimalMoveOutEvent,None",
        included_kinds: "user",
        include_null_dry_matter: "true",
        date_event_end_gte: time1YearAgo.toISOString(),
      })
    );

    if (ids.length > 0) {
      return dispatch(getManyReadingsBulk(ids));
    }
  }
);

export const getManyReadingsBulk = createAsyncThunk(
  "readings/getManyBulk",
  async (ids: Array<string>) => manualInputApi.getManyBulk(ids)
);

export const getOneReading = createAsyncThunk(
  "readings/getOne",
  async (id: string) => manualInputApi.getOne(id)
);

export const deleteOneReading = createAsyncThunk(
  "readings/deleteOne",
  async (id: string, { dispatch }) => {
    const res = await manualInputApi.deleteOne(id);

    if (res.ok) {
      dispatch(readingsSlice.actions.removeOneReading(id));
    }
    return res.status;
  }
);

const prepareReadingRequest = (
  manualInput: ManualDMInputResponse
): ManualDMInputResponse => ({
  guid: manualInput.guid,
  manualDryMatterInput: {
    ...manualInput.manualDryMatterInput,
    dryMatterValue:
      manualInput.manualDryMatterInput.dryMatterValue &&
      manualInput.manualDryMatterInput.dryMatterValue / 10000,
    dateTimeMeasurementStart:
      manualInput.manualDryMatterInput.dateTimeMeasurementStart,
    dateTimeMeasurementEnd:
      manualInput.manualDryMatterInput.dateTimeMeasurementEnd,
    event: {
      ...manualInput.manualDryMatterInput.event,
      postEventCover:
        manualInput.manualDryMatterInput.event.postEventCover &&
        manualInput.manualDryMatterInput.event.postEventCover / 10000,
      preEventCover:
        manualInput.manualDryMatterInput.event.preEventCover &&
        manualInput.manualDryMatterInput.event.preEventCover / 10000,
    },
  },
});

const processReadingResponse = (
  manualInput: ManualDMInputResponse
): ManualDMInputResponse => ({
  guid: manualInput.guid,
  manualDryMatterInput: {
    ...manualInput.manualDryMatterInput,
    dryMatterValue:
      manualInput.manualDryMatterInput.dryMatterValue &&
      manualInput.manualDryMatterInput.dryMatterValue * 10000,
    event: {
      ...manualInput.manualDryMatterInput.event,
      postEventCover:
        manualInput.manualDryMatterInput.event.postEventCover &&
        manualInput.manualDryMatterInput.event.postEventCover * 10000,
      preEventCover:
        manualInput.manualDryMatterInput.event.preEventCover &&
        manualInput.manualDryMatterInput.event.preEventCover * 10000,
    },
  },
});

export const createOneReading = createAsyncThunk(
  "readings/createOne",
  async (manualInput: ManualDMInputResponse) => {
    const res = await manualInputApi.createOne(
      prepareReadingRequest(manualInput)
    );
    return manualInputApi.getOne(res.guid);
  }
);

export const updateOneReading = createAsyncThunk(
  "readings/updateOne",
  async (manualInput: ManualDMInputResponse) => {
    await manualInputApi.updateOne(prepareReadingRequest(manualInput));
    return manualInputApi.getOne(manualInput.guid);
  }
);

export const createManyReadings = createAsyncThunk(
  "readings/createMany",
  async (manualInputs: Array<ManualDMInputResponse>) => {
    const preparedInputs = manualInputs.map((m) => prepareReadingRequest(m));

    const res = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/v1/manualDryMatterInput/bulk`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ manualDryMatterInputs: preparedInputs }),
      }
    );
    return res.json();
  }
);

type Status = "success" | "error" | null;
type LoadingState = "idle" | "pending";

type ReadingsState = EntityState<ManualDMInputResponse> & {
  loading: LoadingState;
  error: null;
  status: Status;
};

const initialReadingsState: ReadingsState = manualInputAdapter.getInitialState({
  loading: "idle",
  error: null,
  status: null,
});

const readingsSlice = createSlice({
  name: "readings",
  initialState: initialReadingsState,
  reducers: {
    resetReadings: () => initialReadingsState,
    removeOneReading: (state, action: PayloadAction<string>) => {
      manualInputAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: {
    [createManyReadings.pending.type]: (state, action) => {
      if (state.loading === "idle") {
        state.loading = "pending";
        state.status = null;
      }
    },
    [createManyReadings.fulfilled.type]: (state, action) => {
      if (state.loading === "pending") {
        state.loading = "idle";
        state.status = "success";
      }
    },
    [createManyReadings.rejected.type]: (state, action) => {
      if (state.loading === "pending") {
        state.loading = "idle";
        state.status = "error";
        state.error = action.error;
      }
    },
    [createOneReading.pending.type]: (state, action) => {
      if (state.loading === "idle") {
        state.loading = "pending";
        state.status = null;
      }
    },
    [createOneReading.fulfilled.type]: (
      state,
      action: PayloadAction<ManualDMInputResponse>
    ) => {
      if (state.loading === "pending") {
        manualInputAdapter.upsertOne(
          state,
          processReadingResponse(action.payload)
        );
        state.loading = "idle";
        state.status = "success";
      }
    },
    [createOneReading.rejected.type]: (state, action) => {
      if (state.loading === "pending") {
        state.loading = "idle";
        state.status = "error";
        state.error = action.error;
      }
    },
    [getOneReading.pending.type]: (state, action) => {
      state.loading = "pending";
      state.status = null;
    },
    [getOneReading.fulfilled.type]: (
      state,
      action: PayloadAction<ManualDMInputResponse>
    ) => {
      manualInputAdapter.upsertOne(
        state,
        processReadingResponse(action.payload)
      );
      state.loading = "idle";
      state.status = "success";
    },
    [getOneReading.rejected.type]: (state, action) => {
      state.loading = "idle";
      state.status = "error";
      state.error = action.error;
    },
    [getManyReadingsBulk.pending.type]: (state, action) => {
      state.loading = "pending";
      state.status = null;
    },
    [getManyReadingsBulk.fulfilled.type]: (
      state,
      action: PayloadAction<Array<ManualDMInputResponse>>
    ) => {
      manualInputAdapter.upsertMany(
        state,
        action.payload.map(processReadingResponse)
      );
      state.loading = "idle";
      state.status = "success";
    },
    [getManyReadingsBulk.rejected.type]: (state, action) => {
      state.loading = "idle";
      state.status = "error";
      state.error = action.error;
    },
    [updateOneReading.pending.type]: (state, action) => {
      state.loading = "pending";
      state.status = null;
    },
    [updateOneReading.fulfilled.type]: (
      state,
      action: PayloadAction<ManualDMInputResponse>
    ) => {
      manualInputAdapter.upsertOne(
        state,
        processReadingResponse(action.payload)
      );
      state.loading = "idle";
      state.status = "success";
    },
    [updateOneReading.rejected.type]: (state, action) => {
      state.loading = "idle";
      state.status = "error";
      state.error = action.error;
    },
    [deleteOneReading.pending.type]: (state) => {
      state.loading = "pending";
      state.status = null;
    },
    [deleteOneReading.fulfilled.type]: (state) => {
      state.loading = "idle";
      state.status = "success";
    },
    [deleteOneReading.rejected.type]: (state, action) => {
      state.loading = "idle";
      state.status = "error";
      state.error = action.error;
    },
  },
});

export const readingsReducer = readingsSlice.reducer;
export const { resetReadings } = readingsSlice.actions;
