import {
  Backdrop,
  Container,
  Dialog,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { LogoIcon } from "common/icons";
import { useTranslation } from "common/locales";

import LoginBackground from "./LoginDialogBackground.svg";

const PREFIX = "FullScreenDialog";

const classes = {
  paper: `${PREFIX}-paper`,
};

const StyledDiv = styled("div")(({ theme }: { theme: Theme }) => ({
  paddingTop: "env(safe-area-inset-top)",
  paddingBottom: "env(safe-area-inset-bottom)",
}));

const StyledProductNameContainer = styled(Container)(
  ({ theme }: { theme: Theme }) => ({
    textAlign: "center",
    color: "black",
    top: "10%",
    position: "absolute",
  })
);

const StyledBackdrop = styled(Backdrop)(({ theme }: { theme: Theme }) => ({
  backgroundImage: `url(${LoginBackground})`,
  backgroundSize: "cover",
}));

const StyledDialog = styled(Dialog)(({ theme }: { theme: Theme }) => ({
  minWidth: `${theme.breakpoints.values.xs}px`,
  [`& .${classes.paper}`]: {
    [theme.breakpoints.up("sm")]: {
      minWidth: "450px",
    },
  },
}));

export const FullScreenDialog = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <StyledDialog
      open
      fullScreen={isMobile}
      disableEscapeKeyDown
      BackdropComponent={LoginBackdrop}
      classes={classes}
    >
      <StyledDiv>{children}</StyledDiv>
    </StyledDialog>
  );
};

const LoginBackdrop = () => {
  const { t } = useTranslation();
  return (
    <StyledBackdrop open={true}>
      <StyledProductNameContainer>
        <Typography variant="h4">
          <LogoIcon /> ruumi
        </Typography>
      </StyledProductNameContainer>
    </StyledBackdrop>
  );
};
