import DropdownInput from "common/components/DropdownInput";
import { styled } from "@mui/material/styles";
import { useTranslation } from "common/locales";
import {
  Theme,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { SEEDING_METHOD } from "model/reseedEventSlice";

const PREFIX = "ReseedMethodDropDown";

const classes = {
  formControl: `${PREFIX}-formControl`,
};

const StyledFormControl = styled(FormControl)(
  ({ theme }: { theme: Theme }) => ({
    [`&.${classes.formControl}`]: {
      maxWidth: 400,
      paddingBottom: theme.spacing(2),
    },
  })
);

interface SeedingMethodDropdownProps {
  seedingMethod: string;
  onChange: (value?: SEEDING_METHOD) => void;
  seedingMethodInputError: boolean;
  label: string;
}

export default function SeedingMethodDropdown({
  onChange,
  seedingMethod,
  seedingMethodInputError,
  label,
}: SeedingMethodDropdownProps) {
  const { t } = useTranslation();

  return (
    <StyledFormControl
      className={classes.formControl}
      error={seedingMethodInputError}
      fullWidth
    >
      <InputLabel
        id="seedingMethod-select-label"
        variant="standard"
        shrink={true}
        error={seedingMethodInputError}
      >
        {t(label)}
      </InputLabel>
      <Select
        labelId="seedingMethod-select-label"
        id="seedingMethod-select"
        value={seedingMethod ?? ""}
        onChange={onChangeSelect}
        input={<DropdownInput />}
        error={seedingMethodInputError}
      >
        {Object.entries(SEEDING_METHOD).map(([key, value]) => (
          <MenuItem value={value} key={key}>
            {t(`reseedEvent.seedingMethod.option.${value}`)}
          </MenuItem>
        ))}
      </Select>
    </StyledFormControl>
  );

  function onChangeSelect(e: any) {
    onChange(e.target.value);
  }
}
