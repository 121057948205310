import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import { Capacitor } from "@capacitor/core";
import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  TextField,
} from "@mui/material";
import { useTranslation } from "common/locales";
import { useAppDispatch } from "model";
import {
  createDiaryEntry,
  CreateDiaryEntryPayload,
} from "model/diaryEntrySlice";
import {
  ImageUploadPayload,
  uploadFarmerImage,
} from "model/farmerImageUploadSlice";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";

interface Props {
  isOpen: boolean;
  close: (newValue: string | null) => void;
  fieldGuid: string;
}

export default function NewDiaryEntryDialog({
  isOpen,
  close,
  fieldGuid,
}: Props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [selectedDate, setSelectedDate] = useState<string>(
    new Date().toISOString().split("T")[0]
  );
  const [isUploading, setIsUploading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  const [base64Image, setBase64Image] = useState<string | null>(null);

  const handlePhotoLibrary = async () => {
    const image = await Camera.getPhoto({
      resultType: CameraResultType.DataUrl,
      source: CameraSource.Photos,
      quality: 90,
    });

    const dataUrl = image.dataUrl;

    if (dataUrl) {
      // Strip out the "data:image/jpeg;base64," prefix
      const base64Data = dataUrl.split(",")[1];
      setImagePreview(dataUrl); // Keep the full dataUrl for preview
      setBase64Image(base64Data); // Store just the base64 string for API
    } else {
      setImagePreview(null);
      setBase64Image(null);
    }
  };

  const handleCameraCapture = async () => {
    const image = await Camera.getPhoto({
      resultType: CameraResultType.DataUrl,
      source: CameraSource.Camera,
      quality: 90,
    });

    const dataUrl = image.dataUrl;

    if (dataUrl) {
      // Strip out the "data:image/jpeg;base64," prefix
      const base64Data = dataUrl.split(",")[1];
      setImagePreview(dataUrl); // Keep the full dataUrl for preview
      setBase64Image(base64Data); // Store just the base64 string for API
    } else {
      setImagePreview(null);
      setBase64Image(null);
    }
  };

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDate(event.target.value);
  };

  const handleSubmit = async () => {
    if (!imagePreview || !base64Image) {
      return;
    }

    setIsUploading(true);
    setUploadSuccess(false);
    setUploadError(false);

    try {
      // Generate a random image name using UUID
      const imageName = `diary_entry_${uuidv4()}.jpg`;

      // Prepare the request payload for image upload
      const imagePayload: ImageUploadPayload = {
        imageName,
        image: base64Image, // Use the base64 string without the prefix
      };

      // Step 1: Upload the image
      const imageUploadResult = await dispatch(uploadFarmerImage(imagePayload));

      if (uploadFarmerImage.fulfilled.match(imageUploadResult)) {
        console.log("Image upload successful:", imageUploadResult.payload);

        // Format the entry date with current time if it's today
        let entryDateWithTime = selectedDate;
        const today = new Date().toISOString().split("T")[0];

        if (selectedDate === today) {
          // If the selected date is today, include the current time
          const now = new Date();
          entryDateWithTime = `${selectedDate}T${now
            .getHours()
            .toString()
            .padStart(2, "0")}:${now
            .getMinutes()
            .toString()
            .padStart(2, "0")}:00`;
        } else {
          // For other dates, use noon as the default time
          entryDateWithTime = `${selectedDate}T12:00:00`;
        }

        // Step 2: Create diary entry with the image ID
        const diaryEntryPayload: CreateDiaryEntryPayload = {
          diaryEntryText: "", // Empty text as specified
          farmerImageUploadId: imageUploadResult.payload,
          fieldGuid: fieldGuid,
          entryDate: entryDateWithTime,
        };

        const diaryEntryResult = await dispatch(
          createDiaryEntry(diaryEntryPayload)
        );

        if (createDiaryEntry.fulfilled.match(diaryEntryResult)) {
          console.log(
            "Diary entry created successfully:",
            diaryEntryResult.payload
          );
          setUploadSuccess(true);

          // Close the dialog after successful creation
          setTimeout(() => {
            close(diaryEntryResult.payload);
          }, 1500);
        } else {
          throw new Error("Failed to create diary entry");
        }
      } else {
        throw new Error("Failed to upload image");
      }
    } catch (error) {
      console.error("Error in submission process:", error);
      setUploadError(true);
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <Dialog
      fullScreen={false}
      open={isOpen}
      onClose={() => {
        close(null);
      }}
    >
      <DialogTitle>Diary Entry</DialogTitle>
      <DialogContent
        style={{
          minWidth: "300px",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <TextField
          id="date"
          label="Select Date"
          type="date"
          value={selectedDate}
          onChange={handleDateChange}
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
        />
        <Button
          variant="contained"
          component="label"
          onClick={handlePhotoLibrary}
          style={{ whiteSpace: "nowrap" }}
        >
          Select Image
        </Button>
        {Capacitor.isNativePlatform() && (
          <Button
            variant="contained"
            onClick={handleCameraCapture}
            style={{ whiteSpace: "nowrap" }}
          >
            Take Photo
          </Button>
        )}
        {imagePreview && (
          <img src={imagePreview} alt="Preview" style={{ maxWidth: "100%" }} />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            close(null);
          }}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          disabled={!imagePreview || isUploading}
        >
          {isUploading ? <CircularProgress size={24} /> : "Submit"}
        </Button>
      </DialogActions>
      <Snackbar
        open={uploadSuccess}
        autoHideDuration={3000}
        onClose={() => setUploadSuccess(false)}
      >
        <Alert severity="success">Image uploaded successfully!</Alert>
      </Snackbar>
      <Snackbar
        open={uploadError}
        autoHideDuration={3000}
        onClose={() => setUploadError(false)}
      >
        <Alert severity="error">
          Failed to upload image. Please try again.
        </Alert>
      </Snackbar>
    </Dialog>
  );
}
