import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MenuIcon from "@mui/icons-material/Menu";
import { Divider, IconButton, Typography } from "@mui/material";
import { ConfirmCancellationDialog } from "common/components";
import { Profile } from "features/userProfile";
import { closeDrawer, openDrawer, useAppDispatch, useAppSelector } from "model";
import { PropsWithChildren, useState } from "react";
import styled, { css } from "styled-components";

interface HeaderBarProps {
  nested?: boolean;
  title: string;
  needsConfirm?: boolean;
  onBack?: () => void;
  burgerColor?: string;
}

export default function HeaderBar({
  nested = false,
  title,
  needsConfirm = false,
  onBack,
  children,
  burgerColor,
}: PropsWithChildren<HeaderBarProps>) {
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const drawerOpen = useAppSelector((state) => state.app.drawerOpen);
  const dispatch = useAppDispatch();

  const hasBackButton = drawerOpen && nested;

  return (
    <>
      <Toolbar>
        <DrawerToggleButton
          onClick={hasBackButton ? handleBackButtonClick : handleDrawerToggle}
          open={drawerOpen}
        >
          {hasBackButton ? (
            <ArrowBackIcon />
          ) : (
            <MenuIcon sx={{ color: burgerColor }} />
          )}
        </DrawerToggleButton>
        {drawerOpen && <Typography variant="h6">{title}</Typography>}
        {nested && (
          <ConfirmCancellationDialog
            open={confirmDialogOpen}
            onClose={handleConfirmationClose}
          />
        )}
        {children}
        <Profile />
      </Toolbar>
      <Divider />
    </>
  );

  function handleConfirmationClose() {
    setConfirmDialogOpen(false);
  }

  function handleBackButtonClick() {
    if (needsConfirm) {
      setConfirmDialogOpen(true);
    } else {
      if (onBack) onBack();
    }
  }

  function handleDrawerToggle() {
    if (drawerOpen) {
      dispatch(closeDrawer());
    } else {
      dispatch(openDrawer());
    }
  }
}

const Toolbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: env(safe-area-inset-top);
`;

const DrawerToggleButton = styled(IconButton)<{ open: boolean }>(
  ({ theme, open }) => css`
    margin: ${theme.spacing(2)};
    ${!open &&
    css`
      background: white;
      box-shadow: ${theme.shadows[2]};

      :hover {
        background: white;
      }
    `}
  `
);
