import { Create, HelpOutline } from "@mui/icons-material";
import { Button, ButtonGroup, Tooltip } from "@mui/material";
import { useTranslation } from "common/locales";
import { _useMapControl as useMapControl } from "react-map-gl";
import styled, { css } from "styled-components";

interface Props {
  onHelpClick: () => void;
  onFieldDrawClick: (e: any) => void;
  onDelete: () => void;
  canDeleteAnything: boolean;
  deleteIcon: JSX.Element;
  isEditing: boolean; // covers both tentative and editing regular features
  deleteTooltip: string;
}
export default function MapEditDrawTools({
  onHelpClick,
  onFieldDrawClick,
  onDelete,
  canDeleteAnything,
  isEditing,
  deleteIcon,
  deleteTooltip,
}: Props) {
  const { t } = useTranslation();
  const { containerRef } = useMapControl({
    captureScroll: true,
    captureDrag: true,
    captureClick: true,
    captureDoubleClick: true,
    capturePointerMove: true,
  });

  return (
    <DrawControl ref={containerRef}>
      <ButtonGroup
        orientation="vertical"
        color="drawTool"
        variant="contained"
        aria-label={t("map.drawfield.drawTools.aria")}
        size="large"
        disableElevation
      >
        <Tooltip title={t("map.drawfield.tooltip")} placement="right">
          <DrawControlButton
            color="drawTool"
            $pressed={isEditing}
            variant="contained"
            onClick={onFieldDrawClick}
            size="large"
          >
            <Create />
          </DrawControlButton>
        </Tooltip>
        <Tooltip title={t(deleteTooltip)} placement="right">
          <DrawControlButton
            color="drawTool"
            onClick={!canDeleteAnything ? undefined : onDelete}
            {...{ component: "div" }} // otherwise tooltip can't be shown for disabled element https://stackoverflow.com/questions/61115913/is-it-possible-to-render-a-tooltip-on-a-disabled-mui-button-within-a-buttongroup
            disabled={!canDeleteAnything}
            variant="contained"
            size="large"
          >
            {deleteIcon}
          </DrawControlButton>
        </Tooltip>
        <Tooltip title={t("map.drawfield.help.tooltip")} placement="right">
          <DrawControlButton
            color="drawTool"
            onClick={onHelpClick}
            variant="contained"
            size="large"
          >
            <HelpOutline />
          </DrawControlButton>
        </Tooltip>
      </ButtonGroup>
    </DrawControl>
  );
}

const DrawControl = styled.div(
  ({ theme }) => `
  background-color: #e0e0e0;
  border-radius: ${theme.spacing(1)};
  position: fixed;
  top: ${theme.spacing(1)};
  left: 416px;
  ${theme.breakpoints.down("sm")} {
    left: ${theme.spacing(1)};
    top: calc(${theme.spacing(14)} + env(safe-area-inset-top));
  }
`
);

// TODO: move colors to the theme
export const DrawControlButton = styled(Button)<{ $pressed?: boolean }>`
  width: 3rem;
  height: 3rem;
  min-width: auto;

  &.Mui-disabled {
    pointer-events: auto;
  }

  &.MuiButtonGroup-groupedContainedVertical:not(:last-child) {
    // hacking because default :not(:last-of-type) doesn't work with "div" that we provide to disabled component
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom: 1px solid #bdbdbd;
    border-color: #9c9c9c;
  }

  &.MuiButtonGroup-groupedContainedVertical:not(:first-child) {
    // hacking because default :not(:first-of-type) doesn't work with "div" that we provide to disabled component
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }

  ${({ $pressed }) =>
    $pressed &&
    css`
      background-color: #9b9c9e !important; // https://github.com/mui/material-ui/issues/15000
      @media (hover: hover) {
        &:hover {
          background: "#7e7e7e";
        }
      }
    `}
`;
