import { useMemo } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useTranslation } from "common/locales";
import {
  DIARY_ENTRY_REJECTION_PERIOD_DAYS,
  DiaryEntry,
  FarmerImageUpload,
  selectFarmerImageUploadById,
  useAppSelector,
} from "model";
import { getFormattedTime } from "common/utils/getFormattedTime";
import DiaryEntryConfirmDialog from "./DiaryEntryConfirmDialog";
import { differenceInDays } from "date-fns";
import { DiaryEntryImage } from "./DiaryEntryImage";

interface Props {
  diaryEntry: DiaryEntry;
  openDiaryEntryDialogGuid: string | null;
  setOpenDiaryEntryDialogGuid: (newValue: string | null) => void;
}

export default function DiaryEntryDialog({
  diaryEntry,
  openDiaryEntryDialogGuid,
  setOpenDiaryEntryDialogGuid,
}: Props) {
  const { t } = useTranslation();
  const farmerImageUpload: FarmerImageUpload | undefined = useAppSelector(
    (state) =>
      selectFarmerImageUploadById(state, diaryEntry.farmerImageUploadId)
  );
  const locale = useAppSelector((state) => state.app.locale);
  const formattedTime = getFormattedTime(diaryEntry.entryDate, locale);
  const canEdit = useMemo(() => {
    return (
      differenceInDays(
        Date.now(),
        new Date(diaryEntry.creationDate) ?? Date.now()
      ) <= DIARY_ENTRY_REJECTION_PERIOD_DAYS
    );
  }, [diaryEntry.creationDate]);

  return (
    <Dialog
      fullScreen={false}
      open={openDiaryEntryDialogGuid === diaryEntry.guid}
      onClose={() => {
        setOpenDiaryEntryDialogGuid(null);
      }}
    >
      <DialogTitle>
        <IconButton
          onClick={() => {
            setOpenDiaryEntryDialogGuid(null);
          }}
        >
          <ArrowBackIosIcon />
        </IconButton>
        {t("diaryEntry.showImage.title", {
          diaryEntryDate: formattedTime,
        })}
      </DialogTitle>
      <DialogContent style={{ maxWidth: "300px" }}>
        <DiaryEntryImage
          farmerImageUpload={farmerImageUpload}
          style={{ maxWidth: "300px", width: "100%", height: "auto" }}
          onImageClick={function (): void {
            const x = 1;
          }}
        />
        <Typography>{diaryEntry.diaryEntryText}</Typography>
      </DialogContent>
      <DialogActions>
        <DiaryEntryConfirmDialog
          canEdit={canEdit}
          diaryEntry={diaryEntry}
          setOpenDiaryEntryDialogGuid={setOpenDiaryEntryDialogGuid}
        ></DiaryEntryConfirmDialog>
      </DialogActions>
    </Dialog>
  );
}
