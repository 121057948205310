import {
  createAction,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";
import { bbox, BBox, FeatureCollection } from "@turf/turf";
import { API } from "common/utils";
import {
  RootState,
  selectArableFieldById,
  selectFieldById,
  selectFieldIds,
} from "model";
import { IPLookup, Location, selectPostcode } from "model/geolocation";
import { selectTmpDeletedFeatures } from "./appSlice";
import {
  fetchIPLocation,
  fetchPostcodes,
  getNavigatorGeolocation,
  Postcode,
} from "./geolocation";

type Visibility = "none" | "visible";
type Scheme = "xyz" | "tms";

export type Tile = {
  guid: string;
  name: string;
  type: string;
  tiles: string[];
  scheme: Scheme;
  bounds: BBox;
  tileSize: number;
  minzoom: number;
  maxzoom: number;
  visibility: Visibility;
  field?: string;
};

export type TileResponse = {
  guid: string;
  name: string;
  scheme: Scheme;
  geom: FeatureCollection;
  tileSize: number;
  minZoom: number;
  maxZoom: number;
  field?: string;
};

const tileAdapter = createEntityAdapter<Tile>({
  selectId: (tile) => tile.guid,
  sortComparer: (a, b) => a.guid.localeCompare(b.guid),
});

export const {
  selectAll: selectAllTiles,
  selectTotal: selectNrOfTiles,
  selectIds: selectTileIds,
} = tileAdapter.getSelectors((state: RootState) => state.map);

export const selectFieldTiles = createSelector(selectAllTiles, (tiles) =>
  tiles.filter((t) => t.field)
);

export const selectFieldTilesForNotDeletedBoundaries = createSelector(
  // hide features that are being edited and boundary removed
  selectFieldTiles,
  selectTmpDeletedFeatures,
  (tiles, tmpDeletedFeatures) =>
    tiles.filter((t) => t.field && tmpDeletedFeatures.indexOf(t.field) === -1)
);

type TilesByFieldIds = {
  [id: string]: Array<Tile>;
};

// To get the latest tile for every field, first group tiles by field id,
// then get the latest by taking the latest timestamp,
// which we currently store in tile.name as a string

const selectTilesByFieldId = createSelector(
  selectFieldTiles,
  (state: RootState) => selectFieldIds(state), // only select tiles that are from current fields
  (tiles, fieldIds) =>
    tiles.reduce((acc: TilesByFieldIds, tile) => {
      // field is optional for tiles
      if (tile.field && fieldIds.includes(tile.field)) {
        if (!acc[tile.field]) {
          acc[tile.field] = [];
        }
        acc[tile.field].push(tile);
      }
      return acc;
    }, {})
);

const selectItemId = (state: RootState, itemId: string) => itemId;

export const selectTilesForField = createSelector(
  [selectTilesByFieldId, selectItemId],
  (tiles, fieldId) => tiles[fieldId]
);

// for now we have the convention that the tile name is a string of the capture date and created timestamp like so: 20200915-1598003965.
export const selectLatestFieldTiles = createSelector(
  selectTilesByFieldId,
  (tilesByFieldIds) => Object.values(tilesByFieldIds).map((tiles) => tiles[0])
);

const tileApi = new API<TileResponse>("tiles");

export const fetchTiles = createAsyncThunk("tile/fetchIds", async () =>
  tileApi.getIds()
);

export const fetchTilesByIds = createAsyncThunk(
  "tile/fetchByIds",
  async (tileIds: Array<string>) => tileApi.getMany(tileIds)
);

// TODO: figure out how to type this
export const deleteAllTilesForField: any = createAsyncThunk(
  "tile/delete",
  async (fieldGuid: string, { getState }) => {
    let tiles: string[] | undefined = selectFieldById(
      getState() as RootState,
      fieldGuid
    )?.tiles;
    if (!tiles) {
      tiles = selectArableFieldById(getState() as RootState, fieldGuid)?.tiles;
    }
    if (tiles) {
      await Promise.all(tiles.map((t) => tileApi.deleteOne(t)));
      return tiles;
    }
  }
);

const processResponse = (tiles: Array<TileResponse>): Array<Tile> =>
  tiles.map((tile) => ({
    ...tile,
    type: "raster",
    tiles: [
      `${process.env.REACT_APP_API_ENDPOINT}/v1/tiles/${tile.guid}/{z}/{x}/{y}.png`,
    ],
    bounds: bbox(tile.geom), // tiles bbox
    minzoom: tile.minZoom, // highest zoom level we created tiles for
    maxzoom: tile.maxZoom, // lowest zoom level we created tiles for
    visibility: "visible",
  }));

export const setIsDraggingAnimalMarker = createAction<boolean>(
  "map/setIsDragginAnimalMarker"
);

type Status = "success" | "error" | null;
type LoadingState = "idle" | "pending";

type MapState = EntityState<Tile> & {
  loading: LoadingState;
  error: null;
  status: Status;
  postcodeLocation: Location | undefined;
  navigatorLocation: Location | undefined;
  zoomToGeom?: FeatureCollection;
  tmpPostcodeList: Postcode[];
  postcodeInputError: boolean;
  isDraggingAnimalMarker: boolean;
};

const initialState: MapState = tileAdapter.getInitialState({
  loading: "idle",
  error: null,
  status: null,
  postcodeLocation: undefined,
  navigatorLocation: undefined,
  zoomToGeom: undefined,
  tmpPostcodeList: [],
  postcodeInputError: false,
  isDraggingAnimalMarker: false,
});

const mapSlice = createSlice({
  name: "map",
  initialState,
  reducers: {
    resetMap: () => initialState,
    setZoomToGeom: (state, action: PayloadAction<FeatureCollection>) => {
      state.zoomToGeom = action.payload;
    },
    removeTile: (state, action: PayloadAction<Array<string>>) => {
      const tileToRemove = action.payload.filter((element) =>
        state.ids.includes(element)
      );
      if (tileToRemove.length === 1) {
        tileAdapter.removeOne(state, tileToRemove[0]);
      }
    },
  },
  extraReducers: {
    "tile/madeInvisible": (state, action: PayloadAction<string>) => {
      // right now we can't actually remove a tile from the store because of the way are stopping the useEffect for getting new tiles. instead, we make the tiles of a deleted field invisible.
      if (state.entities[action.payload]) {
        state.entities[action.payload]!.visibility = "none";
      }
    },
    [fetchTilesByIds.pending.type]: (state, action) => {
      state.loading = "pending";
    },
    [fetchTilesByIds.fulfilled.type]: (state, action) => {
      state.loading = "idle";
      tileAdapter.upsertMany(state, processResponse(action.payload));
    },
    [fetchTilesByIds.rejected.type]: (state, action) => {
      state.loading = "idle";
      state.error = action.error;
    },
    [deleteAllTilesForField.pending.type]: (state, action) => {
      state.loading = "pending";
    },
    [deleteAllTilesForField.fulfilled.type]: (state, action) => {
      state.loading = "idle";
      tileAdapter.removeMany(state, action.payload);
    },
    [deleteAllTilesForField.rejected.type]: (state, action) => {
      state.loading = "idle";
      state.error = action.error;
    },
    [fetchPostcodes.pending.type]: (state, action) => {
      state.loading = "pending";
    },
    [fetchPostcodes.fulfilled.type]: (
      state,
      action: PayloadAction<Postcode[]>
    ) => {
      state.loading = "idle";
      state.tmpPostcodeList = action.payload;
    },
    [fetchPostcodes.rejected.type]: (state, action) => {
      state.loading = "idle";
      state.error = action.error;
      state.tmpPostcodeList = [];
    },
    [getNavigatorGeolocation.pending.type]: (state, action) => {
      state.loading = "pending";
    },
    [getNavigatorGeolocation.fulfilled.type]: (
      state,
      action: PayloadAction<Postcode>
    ) => {
      state.loading = "idle";
      state.navigatorLocation = {
        latitude: action.payload.latitude,
        longitude: action.payload.longitude,
      };
    },
    [getNavigatorGeolocation.rejected.type]: (state, action) => {
      state.loading = "idle";
      state.error = action.error;
    },
    [fetchIPLocation.pending.type]: (state, action) => {
      state.loading = "pending";
    },
    [fetchIPLocation.fulfilled.type]: (
      state,
      action: PayloadAction<IPLookup>
    ) => {
      state.loading = "idle";
      if (!state.navigatorLocation) {
        // this should be called once on the app start and we overwrite
        // only if there is no navigator geolocation
        state.navigatorLocation = {
          latitude: action.payload.latitude,
          longitude: action.payload.longitude,
        };
      }
    },
    [fetchIPLocation.rejected.type]: (state, action) => {
      state.loading = "idle";
      state.error = action.error;
    },
    [selectPostcode.type]: (state, action: PayloadAction<Postcode>) => {
      state.postcodeLocation = {
        latitude: action.payload.latitude,
        longitude: action.payload.longitude,
      };
    },
    [setIsDraggingAnimalMarker.type]: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isDraggingAnimalMarker = action.payload;
    },
  },
});

export const mapReducer = mapSlice.reducer;

export const { setZoomToGeom, removeTile, resetMap } = mapSlice.actions;
