import {
  Box,
  Button,
  Container,
  Grid,
  LinearProgress,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "common/locales";
import { Form, Formik } from "formik";
import {
  ArableField,
  Farm,
  FERTILIZER_TOTAL_AMOUNT_UNIT,
  FERTILIZER_TYPES,
  FertilizerEvent,
  Field,
  MACHINERY,
  NITROGEN_FERTILIZER_TYPE,
  ORGANIC_FERTILIZER_ANIMAL_TYPES,
  ORGANIC_FERTILIZER_APPLICATION_METHOD,
  ORGANIC_FERTILIZER_TYPES,
  selectAllArableFields,
  selectAllFields,
  selectCurrentFarm,
  useAppSelector,
} from "model";
import { useState } from "react";
import { useSelector } from "react-redux";
import styled, { css } from "styled-components";

import { mixpanel } from "common/analytics/mixpanel";
import { StyledCoverAppBar } from "features/bulkCoverInput";
import ConfirmCancellationDialog from "features/general/ConfirmCancellationDialog";
import EnterDate from "features/general/EnterDate";
import GenericDropdown from "features/general/GenericDropdown";
import NameAndGuidMultiSelectDropdown from "features/general/NameAndGuidMultiSelectDropdown";
import * as Yup from "yup";
import EnterFertilizerEventFertilizerProperties from "./EnterFertilizerEventProperties";
import {
  AnyOtherFertilizerEvent,
  FertilizerEventFormData,
  LimeApplicationEvent,
  OrganicFertilizerApplicationEvent,
  SyntheticFertilizerApplicationEvent,
} from "./FertilizerEventFormData";
import { ButtonGroup } from "./generalMethods";

interface Props {
  fertilizerEvent: FertilizerEvent | undefined;
  handleSave: (
    fertilizerInput: FertilizerEventFormData,
    farmGuid: string
  ) => void;
  handleClose: () => void;
  fieldSelectDisabled: boolean;
}

const convertToFormInput = (
  fertilizerEvent: FertilizerEvent
): FertilizerEventFormData => {
  return {
    note: fertilizerEvent.note ?? undefined,
    machinery: fertilizerEvent.machinery ?? undefined,
    operationalCost: fertilizerEvent.operationalCost ?? undefined,
    productCost: fertilizerEvent.productCost ?? undefined,
    fertilizerApplicationDate: new Date(
      fertilizerEvent.fertilizerApplicationDate
    ),
    fertilizerType: fertilizerEvent.fertilizerProperties.fertilizerType,
    fieldGuids: fertilizerEvent.fieldGuids ?? [],
    fertilizerProperties: {
      ...getDefaultFertilizerProperties(),
      ...fertilizerEvent.fertilizerProperties,
    } as
      | OrganicFertilizerApplicationEvent
      | SyntheticFertilizerApplicationEvent
      | LimeApplicationEvent
      | AnyOtherFertilizerEvent,
    fuel: fertilizerEvent.fuel,
    totalAmount: fertilizerEvent.totalAmount,
    totalAmountUnit: fertilizerEvent.totalAmountUnit,
  };
};

const getDefaultFertilizerProperties = ():
  | OrganicFertilizerApplicationEvent
  | SyntheticFertilizerApplicationEvent
  | LimeApplicationEvent
  | AnyOtherFertilizerEvent => {
  return {
    isImported: false,
    organicFertilizerType: "" as unknown as ORGANIC_FERTILIZER_TYPES,
    animalType: [],
    applicationMethod: "" as unknown as ORGANIC_FERTILIZER_APPLICATION_METHOD,
    productName: "",
    nPercentage: "" as unknown as number,
    typeOfNitrogen: "" as unknown as NITROGEN_FERTILIZER_TYPE,
    pPercentage: "" as unknown as number,
    kPercentage: "" as unknown as number,
    phPriorToApplication: "" as unknown as number,
  };
};

export default function EnterFertilizerEvent({
  fertilizerEvent,
  handleSave,
  handleClose,
  fieldSelectDisabled,
}: Props) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const loading = useAppSelector((state) => state.animalGroups.loading);

  const currentFarm: Farm | undefined = useAppSelector(selectCurrentFarm);
  const allGrassFields: Field[] = useSelector(selectAllFields);

  const allArableFields: ArableField[] = useSelector(selectAllArableFields);

  let allFields: (Field | ArableField)[] = [];
  allFields = allFields.concat(allArableFields);
  allFields = allFields.concat(allGrassFields);

  // OrganicFertilizerApplicationEvent Yup Schema
  const OrganicFertilizerApplicationEventSchema = Yup.object({
    isImported: Yup.boolean().required("isImported is required"),
    organicFertilizerType: Yup.mixed<ORGANIC_FERTILIZER_TYPES>()
      .oneOf(Object.values(ORGANIC_FERTILIZER_TYPES))
      .required("Organic fertilizer type is required"),
    animalType: Yup.array()
      .of(
        Yup.mixed<ORGANIC_FERTILIZER_ANIMAL_TYPES>().oneOf(
          Object.values(ORGANIC_FERTILIZER_ANIMAL_TYPES)
        )
      )
      .min(1)
      .required("Animal type is required"),
    applicationMethod: Yup.mixed<ORGANIC_FERTILIZER_APPLICATION_METHOD>()
      .oneOf(Object.values(ORGANIC_FERTILIZER_APPLICATION_METHOD))
      .required("Application method is required"),
  });

  // SyntheticFertilizerApplicationEvent Yup Schema
  const SyntheticFertilizerApplicationEventSchema = Yup.object({
    productName: Yup.string().required("Product name is required"),
    nPercentage: Yup.number()
      .min(0, "N percentage must be at least 0")
      .max(100, "N percentage must be at most 100")
      .required("N percentage is required"),
    typeOfNitrogen: Yup.mixed<NITROGEN_FERTILIZER_TYPE>()
      .oneOf(Object.values(NITROGEN_FERTILIZER_TYPE))
      .required("Nitrogen type is required"),
    pPercentage: Yup.number().min(0).max(100).optional(),
    kPercentage: Yup.number().min(0).max(100).optional(),
  });

  // LimeApplicationEvent Yup Schema
  const LimeApplicationEventSchema = Yup.object({
    phPriorToApplication: Yup.number()
      .min(0)
      .max(14)
      .required("PH prior to application is required"),
  });

  // AnyOtherFertilizerEvent Yup Schema
  const AnyOtherFertilizerEventSchema = Yup.object({});

  const validNumber = (min = 0) =>
    Yup.number()
      .typeError(t("edit.minNumber.error", { min }))
      .moreThan(min, t("edit.minNumber.error", { min }))
      .required(t("edit.required.error"));

  const validNumberGTE = (min = 0) =>
    Yup.number()
      .typeError(t("edit.minNumber.error", { min }))
      .min(min, t("edit.minNumber.error", { min }))
      .required(t("edit.required.error"));

  const validNumberGTEAllowingNull = (min = 0) =>
    Yup.number()
      .nullable()
      .typeError(t("edit.minNumber.error", { min }))
      .min(min, t("edit.minNumber.error", { min }))
      .required(t("edit.required.error"));

  // FertilizerEventFormData Yup Schema
  const FertilizerEventFormDataSchema = Yup.object({
    note: Yup.string().optional(),
    machinery: Yup.array()
      .of(Yup.mixed<MACHINERY>().oneOf(Object.values(MACHINERY)))
      .optional(),
    totalAmount: validNumber(0).required("Total amount is required"),
    totalAmountUnit: Yup.mixed<FERTILIZER_TOTAL_AMOUNT_UNIT>()
      .oneOf(Object.values(FERTILIZER_TOTAL_AMOUNT_UNIT))
      .required("Total amount unit is required"),
    fuel: Yup.lazy((value) =>
      value === "" ? Yup.string() : validNumberGTEAllowingNull(0)
    ).optional(),
    operationalCost: Yup.lazy((value) =>
      value === "" ? Yup.string() : validNumberGTEAllowingNull(0)
    ).optional(),
    productCost: Yup.lazy((value) =>
      value === "" ? Yup.string() : validNumberGTEAllowingNull(0)
    ).optional(),
    fertilizerApplicationDate: Yup.date().required(
      "Fertilizer application date is required"
    ),
    fertilizerType: Yup.mixed<FERTILIZER_TYPES>()
      .oneOf(Object.values(FERTILIZER_TYPES))
      .required("Fertilizer type is required"),
    fieldGuids: Yup.array()
      .of(Yup.string().required())
      .min(1)
      .required("At least one field guid is required"),

    // Conditional validation for fertilizerProperties based on fertilizerType
    fertilizerProperties: Yup.mixed().when(
      "fertilizerType",
      (fertilizerType: FERTILIZER_TYPES[]) => {
        if (fertilizerType[0] === FERTILIZER_TYPES.ORGANIC) {
          return OrganicFertilizerApplicationEventSchema;
        } else if (fertilizerType[0] === FERTILIZER_TYPES.SYNTHETIC) {
          return SyntheticFertilizerApplicationEventSchema;
        } else if (fertilizerType[0] === FERTILIZER_TYPES.LIME) {
          return LimeApplicationEventSchema;
        } else {
          return AnyOtherFertilizerEventSchema;
        }
      }
    ),
  });

  if (loading) {
    return <LinearProgress />;
  }

  const initialFertilizerEvent = fertilizerEvent
    ? convertToFormInput(fertilizerEvent)
    : {
        totalAmount: "" as unknown as number,
        totalAmountUnit: "" as FERTILIZER_TOTAL_AMOUNT_UNIT,
        fertilizerApplicationDate: new Date(),
        fertilizerType: "" as unknown as FERTILIZER_TYPES,
        fieldGuids: [],
        fertilizerProperties: getDefaultFertilizerProperties(),
        machinery: [],
        fuel: "" as unknown as number,
      };
  return (
    <>
      <Formik
        enableReinitialize={false}
        initialValues={initialFertilizerEvent}
        validationSchema={FertilizerEventFormDataSchema}
        validateOnChange={false} // disable validation every field change, otherwise would validate all fields
        validateOnBlur={false} // disable validation every field blur, otherwise would validate all fields
        onSubmit={(fertilizerEventFormData: FertilizerEventFormData) => {
          void handleSave(fertilizerEventFormData, currentFarm?.guid ?? "");
        }}
      >
        {({
          values,
          handleChange,
          errors,
          validateField,
          setFieldValue,
          resetForm,
        }) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const handleInputChange = async (e: any, fieldName: string) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            await (handleChange(e) as any); // async, typings are wrong
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            await (validateField(fieldName) as any); // validate only changed field, async, typings are wrong
          };

          return (
            <>
              <Form id="fertilizer-event-input-form">
                <Container style={{ textAlign: "left", marginBottom: 40 }}>
                  <Typography variant="h6">
                    {t("fertilizerEventInput.title")}
                  </Typography>
                </Container>

                <Container style={{ textAlign: "left", marginBottom: 40 }}>
                  <Grid
                    container
                    display="flex"
                    alignItems="top"
                    marginBottom={4}
                  >
                    <Grid item xs={12} sm={6} display="flex" alignItems="top">
                      <EnterDate
                        disabled={false}
                        date={values.fertilizerApplicationDate}
                        setDate={async (date: Date | null) => {
                          await setFieldValue(
                            "fertilizerApplicationDate",
                            date,
                            false
                          );
                        }}
                        dateError={!!errors.fertilizerApplicationDate}
                        name={"fertilizerApplicationDate"}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} display="flex" alignItems="top">
                      <NameAndGuidMultiSelectDropdown
                        disabled={fieldSelectDisabled}
                        required={true}
                        selectedItems={allFields
                          .filter((field: Field | ArableField) => {
                            return values.fieldGuids.includes(field.guid);
                          })
                          .map((field: Field | ArableField) => {
                            return {
                              guid: field.guid,
                              name: field.name,
                            };
                          })}
                        items={allFields.map((field: Field | ArableField) => {
                          return {
                            name: field.name,
                            guid: field.guid,
                          };
                        })}
                        onChange={(e) => handleInputChange(e, "fieldGuids")}
                        selectInputError={!!errors.fieldGuids}
                        label={t("fertilizerEventInput.fields.text")}
                        name={"fieldGuids"}
                      />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <GenericDropdown
                        disabled={false}
                        onChange={async (event) => {
                          await handleInputChange(event, `fertilizerType`);
                        }}
                        currentValue={values.fertilizerType}
                        inputError={errors?.fertilizerType ? true : false}
                        label={"fertilizerInput.fertilizerType.label"}
                        name={`fertilizerType`}
                        possibleValues={FERTILIZER_TYPES}
                        tPrefix={"fertilizerEvent.fertilizerType.label"}
                      />
                    </Grid>
                  </Grid>
                  <EnterFertilizerEventFertilizerProperties
                    fertilizerProperties={values.fertilizerProperties}
                    handleInputChange={handleInputChange}
                    setFieldValue={setFieldValue}
                    fertilizerType={values.fertilizerType}
                    errors={errors}
                  />
                  <Typography>{t("fertilizer.amount.cost.heading")}</Typography>
                  <Box
                    my={0}
                    mb={5}
                    display="flex"
                    alignItems="center"
                    gap={4}
                    p={3}
                    sx={{ border: "1px solid grey", borderRadius: 2 }}
                  >
                    <Grid
                      container
                      spacing={3}
                      display="flex"
                      alignItems="baseline"
                    >
                      <Grid
                        item
                        xs={12}
                        sm={3}
                        display="flex"
                        alignItems="baseline"
                      >
                        <TextField
                          value={values.totalAmount}
                          type="number"
                          variant="standard"
                          disabled={false}
                          id="fuel"
                          error={errors?.totalAmount ? true : false}
                          onChange={async (event) => {
                            await handleInputChange(event, `totalAmount`);
                          }}
                          label={t("fertilizerEvent.totalAmount.label")}
                          maxRows={1}
                          name={`totalAmount`}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        display="flex"
                        alignItems="baseline"
                      >
                        <GenericDropdown
                          disabled={false}
                          onChange={async (event) => {
                            await handleInputChange(event, `totalAmountUnit`);
                          }}
                          currentValue={values.totalAmountUnit}
                          inputError={errors?.totalAmountUnit ? true : false}
                          label={"fertilizerInput.totalAmountUnit.label"}
                          name={`totalAmountUnit`}
                          possibleValues={FERTILIZER_TOTAL_AMOUNT_UNIT}
                          tPrefix={"fertilizerEvent.totalAmountUnit.option"}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={3}
                        display="flex"
                        alignItems="baseline"
                      >
                        <TextField
                          value={values.productCost}
                          type="number"
                          variant="standard"
                          disabled={false}
                          id="fuel"
                          error={errors?.productCost ? true : false}
                          onChange={async (event) => {
                            await handleInputChange(event, `productCost`);
                          }}
                          label={t("fertilizerEvent.productCost.label")}
                          maxRows={1}
                          name={`productCost`}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Typography>
                    {t("fertilizer.operational.cost.heading")}
                  </Typography>

                  <Box
                    my={0}
                    mb={5}
                    display="flex"
                    alignItems="center"
                    gap={4}
                    p={3}
                    sx={{ border: "1px solid grey", borderRadius: 2 }}
                  >
                    <Grid
                      container
                      spacing={3}
                      display="flex"
                      alignItems="baseline"
                    >
                      <Grid
                        item
                        xs={12}
                        sm={3}
                        display="flex"
                        alignItems="baseline"
                      >
                        <TextField
                          value={values.fuel}
                          type="number"
                          variant="standard"
                          disabled={false}
                          id="fuel"
                          error={errors?.fuel ? true : false}
                          onChange={async (event) => {
                            await handleInputChange(event, `fuel`);
                          }}
                          label={t("fertilizerEvent.fuel.label")}
                          maxRows={1}
                          name={`fuel`}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        display="flex"
                        alignItems="baseline"
                      >
                        <NameAndGuidMultiSelectDropdown
                          required={false}
                          selectedItems={Object.keys(MACHINERY)
                            .filter((key: string) => {
                              return (values.machinery as string[]).includes(
                                key
                              );
                            })
                            .map((key: string) => {
                              return {
                                guid: key,
                                name: t(
                                  `yieldEventInput.machinery.options.${key}`
                                ),
                              };
                            })}
                          items={Object.keys(MACHINERY).map((key: string) => {
                            return {
                              name: t(
                                `yieldEventInput.machinery.options.${key}`
                              ),
                              guid: key,
                            };
                          })}
                          disabled={false}
                          onChange={(e) => handleInputChange(e, "machinery")}
                          selectInputError={!!errors.machinery}
                          label={t("fertilizerEventInput.machinery.label")}
                          name={"machinery"}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={3}
                        display="flex"
                        alignItems="baseline"
                      >
                        <TextField
                          value={values.operationalCost}
                          type="number"
                          variant="standard"
                          disabled={false}
                          id="fuel"
                          error={errors?.operationalCost ? true : false}
                          onChange={async (event) => {
                            await handleInputChange(event, `operationalCost`);
                          }}
                          label={t("fertilizerEvent.operationalCost.label")}
                          maxRows={1}
                          name={`operationalCost`}
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  <Grid container display="flex" alignItems="baseline">
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      display="flex"
                      alignItems="baseline"
                    >
                      <StyledTextField
                        id="fertilizer-event-input-note"
                        label={t("reseedEvent.notes.label")}
                        value={values.note}
                        helperText={errors.note}
                        error={!!errors.note}
                        fullWidth
                        onChange={(e) => handleInputChange(e, "note")}
                        name={"note"}
                        variant="standard"
                        multiline
                      />
                    </Grid>
                  </Grid>
                </Container>
              </Form>
            </>
          );
        }}
      </Formik>
      <StyledCoverAppBar>
        <ButtonGroup>
          <Button color="secondary" onClick={handleCancel}>
            {t("edit.button.cancel")}
          </Button>
          <Button
            color="primary"
            type="submit"
            form="fertilizer-event-input-form"
          >
            {t("edit.button.save")}
          </Button>
        </ButtonGroup>
        <ConfirmCancellationDialog
          dialogOpen={open}
          closeDialog={() => setOpen(false)}
          onConfirmCancel={() => handleConfirmationClose()}
          onCancelCancel={() => setOpen(false)}
        />
      </StyledCoverAppBar>
    </>
  );

  function handleConfirmationClose() {
    mixpanel.track("FertilizerEvent creation cancelled");
    handleClose();
  }

  function handleCancel() {
    setOpen(true);
  }
}

const StyledTextField = styled(TextField)(
  ({ theme }) => css`
                          min - width: 300px;
                    padding - bottom: ${theme.spacing(2)};
                    `
);
