import {
  EditMode,
  GuideFeatureCollection,
  ClickEvent,
  PointerMoveEvent,
  StartDraggingEvent,
  StopDraggingEvent,
  FeatureCollection,
  Tooltip,
  DraggingEvent,
} from "@deck.gl-community/editable-layers";
import { Feature } from "@deck.gl-community/editable-layers/src/utils/geojson-types";
import { ModeProps } from "../types";

import { GEOJSON_TYPE, GUIDE_TYPE } from "../constants";
import { getFeatureCoordinates, isNumeric } from "./utils";

export default class BaseMode
  implements EditMode<FeatureCollection, GuideFeatureCollection>
{
  _tentativeFeature: Feature | null | undefined;
  _editHandles: Feature[] | null | undefined;

  constructor() {
    this._tentativeFeature = null;
    this._editHandles = null;
  }

  handlePan(event: ClickEvent, props: ModeProps<FeatureCollection>) {
    // do nothing.
  }

  handleClick(event: ClickEvent, props: ModeProps<FeatureCollection>) {
    // do nothing.
  }

  handleDblClick(event: ClickEvent, props: ModeProps<FeatureCollection>) {
    // do nothing.
  }

  handlePointerMove(
    event: PointerMoveEvent,
    props: ModeProps<FeatureCollection>
  ) {
    // do nothing.
  }

  handleStartDragging(
    event: StartDraggingEvent,
    props: ModeProps<FeatureCollection>
  ) {
    // do nothing.
  }

  handleStopDragging(
    event: StopDraggingEvent,
    props: ModeProps<FeatureCollection>
  ) {
    // do nothing.
  }

  handleKeyUp(event: KeyboardEvent, props: ModeProps<FeatureCollection>): void {
    // do nothing.
  }

  getGuides(
    props: ModeProps<FeatureCollection>
  ): GuideFeatureCollection | undefined {
    return undefined;
  }
  getTooltips(props: ModeProps<FeatureCollection>): Tooltip[] {
    return [];
  }
  handleDragging(
    event: DraggingEvent,
    props: ModeProps<FeatureCollection>
  ): void {
    // do nothing.
  }

  getTentativeFeature() {
    return this._tentativeFeature;
  }

  getEditHandles() {
    return this._editHandles;
  }

  setTentativeFeature(feature: Feature) {
    this._tentativeFeature = feature;
  }

  getEditHandlesFromFeature(
    feature: Feature,
    featureIndex: number | null | undefined
  ) {
    const coordinates = getFeatureCoordinates(feature);
    if (!coordinates) {
      return null;
    }
    // @ts-expect-error narrow type of coordinates
    //eslint-disable-next-line  @typescript-eslint/no-unsafe-return
    return coordinates.map((coord, i): Feature => {
      return {
        type: "Feature",
        properties: {
          // TODO deprecate renderType
          renderType: feature.properties?.renderType,
          guideType: GUIDE_TYPE.EDIT_HANDLE,
          editHandleType: "existing",
          featureIndex,
          positionIndexes: [i],
        },
        geometry: {
          type: GEOJSON_TYPE.POINT,
          coordinates: coord,
        },
      };
    });
  }

  getSelectedFeature(
    props: ModeProps<FeatureCollection>,
    featureIndex?: number | null
  ) {
    const { data, selectedIndexes } = props;
    const features = data && data.features;

    const selectedIndex = isNumeric(featureIndex)
      ? Number(featureIndex)
      : selectedIndexes && selectedIndexes[0];

    return features && features[selectedIndex];
  }
}
