import { useTranslation } from "common/locales";
import { styled } from "@mui/material/styles";
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Theme,
} from "@mui/material";
import { FIELD_TYPE } from "model";

const PREFIX = "FieldTypeRadio";

const classes = {
  formControl: `${PREFIX}-formControl`,
};

const StyledFormControl = styled(FormControl)(
  ({ theme }: { theme: Theme }) => ({
    [`&.${classes.formControl}`]: {
      maxWidth: 400,
      paddingBottom: theme.spacing(2),
    },
  })
);

interface ArableTypeDropdownProps {
  currentFieldType?: string;
  disabled: boolean;
  onChange: (value?: FIELD_TYPE) => void;
  fieldTypeInputError: boolean;
  label: string;
}

export default function FieldTypeRadio({
  disabled,
  onChange,
  currentFieldType,
  fieldTypeInputError,
  label,
}: ArableTypeDropdownProps) {
  const { t } = useTranslation();
  function onChangeSelect(e: any) {
    onChange(e.target.value);
  }

  return (
    <StyledFormControl
      className={classes.formControl}
      disabled={disabled}
      error={fieldTypeInputError}
      fullWidth
    >
      <FormLabel id="demo-row-radio-buttons-group-label"> {t(label)}</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        onChange={onChangeSelect}
        value={currentFieldType ?? ""}
      >
        {Object.entries(FIELD_TYPE).map(([key, value]) => (
          <FormControlLabel
            value={value}
            control={<Radio />}
            label={t(`fieldinput.fieldType.option.${value}`)}
          />
        ))}
      </RadioGroup>
    </StyledFormControl>
  );
}
