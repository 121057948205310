import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";
import { API } from "common/utils";
import { createSelector } from "reselect";
import { getSingleFarmerImageUpload } from "./farmerImageUploadSlice";
import { RootState } from "./rootReducer";

export type DiaryEntry = {
  guid: string;
  diaryEntryText: string;
  farmerImageUploadId: string;
  fieldGuid: string;
  entryDate: string;
  creationDate: string;
};

export type CreateDiaryEntryPayload = {
  diaryEntryText: string;
  farmerImageUploadId: string;
  fieldGuid: string;
  entryDate: string;
};

export const DIARY_ENTRY_REJECTION_PERIOD_DAYS = 30;

class DiaryEntryAPI extends API<DiaryEntry> {
  async getAllDiaryEntriesForFarm(farmGuid: string): Promise<DiaryEntry[]> {
    const res = await fetch(
      `${this.baseUrl}${this.entity}?farmGuid=${farmGuid}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt") || ""}`,
          "Content-Type": "application/json",
        },
      }
    );
    return (await res.json()) as DiaryEntry[];
  }

  async createDiaryEntry(
    payload: CreateDiaryEntryPayload
  ): Promise<{ guid: string }> {
    const res = await fetch(`${this.baseUrl}${this.entity}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt") || ""}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    if (!res.ok) {
      throw new Error(`HTTP error! Status: ${res.status}`);
    }

    return (await res.json()) as { guid: string };
  }
}

const diaryEntryApi = new DiaryEntryAPI("diaryEntry");

export const deleteDiaryEntry = createAsyncThunk<Promise<any>, DiaryEntry>(
  "diaryEntry/delete",
  async (diaryEntry: DiaryEntry, { dispatch }) => {
    const res = await diaryEntryApi.deleteOne(diaryEntry.guid);
    if (res.ok) {
      dispatch(diaryEntrySlice.actions.deleteDiaryEntry(diaryEntry.guid));
    }
    return res.status;
  }
);

export const createDiaryEntry = createAsyncThunk<
  string,
  CreateDiaryEntryPayload
>("diaryEntry/create", async (payload: CreateDiaryEntryPayload) => {
  const response = await diaryEntryApi.createDiaryEntry(payload);
  return response.guid;
});

export const diaryEntryAdapter = createEntityAdapter<DiaryEntry>({
  // we need this because IDs are stored in a field other than `field.id`
  selectId: (diaryEntry: DiaryEntry) => diaryEntry.guid,
  // Keeps the array sorted by guid
  sortComparer: (a: DiaryEntry, b: DiaryEntry) => a.guid.localeCompare(b.guid),
});

export const {
  selectAll: selectAllDiaryEvents,
  selectById: selectDiaryEntryEventById,
} = diaryEntryAdapter.getSelectors((state: RootState) => state.diaryEntries);

const initialDiaryEntryState: EntityState<DiaryEntry> =
  diaryEntryAdapter.getInitialState({
    loading: false,
    error: null,
    status: null,
  });

export const getAllDiaryEntriesForFarm = createAsyncThunk<
  Promise<Array<DiaryEntry>>,
  { farmId: string }
>(
  "diaryEntries/getDiaryEntriesBulk",
  async ({ farmId }: { farmId: string }, { dispatch }) => {
    const diaryEntries: DiaryEntry[] =
      await diaryEntryApi.getAllDiaryEntriesForFarm(farmId);
    diaryEntries.forEach(async (diaryEntry: DiaryEntry) => {
      await dispatch(
        getSingleFarmerImageUpload({ guid: diaryEntry.farmerImageUploadId })
      );
    });
    return diaryEntries;
  }
);
const selectFieldGuid = (state: RootState, fieldGuid: string) => fieldGuid;

export const selectDiaryEntryEventsOnField = createSelector(
  [selectAllDiaryEvents, selectFieldGuid],
  (diaryEntries: DiaryEntry[], fieldGuid: string) => {
    const diaryEntriesOnField = diaryEntries.filter(
      (r: DiaryEntry) => r.fieldGuid === fieldGuid
    );
    return diaryEntriesOnField.sort((a: DiaryEntry, b: DiaryEntry) => {
      return Date.parse(b.entryDate) - Date.parse(a.entryDate);
    });
  }
);

const diaryEntrySlice = createSlice({
  name: "diaryEntries",
  initialState: initialDiaryEntryState,
  reducers: {
    resetDiaryEntries: () => initialDiaryEntryState,
    deleteDiaryEntry: (state, action: PayloadAction<string>) =>
      diaryEntryAdapter.removeOne(state, action.payload),
  },
  extraReducers: {
    [getAllDiaryEntriesForFarm.fulfilled.type]: (state, action: any) => {
      diaryEntryAdapter.setAll(state, action.payload);
    },
  },
});

export const { resetDiaryEntries } = diaryEntrySlice.actions;

export const diaryEntryReducer = diaryEntrySlice.reducer;
