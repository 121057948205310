import { bbox, FeatureCollection } from "@turf/turf";
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import styled from "styled-components";

import "mapbox-gl/dist/mapbox-gl.css";
import MapGL, {
  FlyToInterpolator,
  MapEvent,
  MapRef,
  NavigationControl,
  ViewportProps,
  WebMercatorViewport,
} from "react-map-gl";

import { useMediaQuery, useTheme } from "@mui/material";
import { colorToDM, useHtmlElementSize } from "common/utils";
import { THROTTLE_MAP_UPDATE_MS } from "features/mapDrawTools/const";
import {
  ArableFieldGeoJSONLayers,
  FieldGeoJSONLayers,
  FieldTileLayers,
  MapCursorTooltip,
  MapEditor,
} from "features/mapLayers";
import { throttle } from "lodash";
import {
  getFieldsWithNoTiles,
  openDrawer,
  useAppDispatch,
  useAppSelector,
} from "model";
import { flushSync } from "react-dom";

interface MatchProps {
  id: string;
}

const initialViewport = {
  latitude: 52.794201,
  longitude: -1.1945036,
  zoom: 12,
  bearing: 0,
  pitch: 0,
  width: 0,
  height: 0,
  altitude: 0,
};

type ViewportAction =
  | { type: "update"; payload: ViewportProps }
  | {
      type: "zoom-to-bounds";
      payload: { geom: FeatureCollection; animate: boolean };
    };

const viewportReducer = (state: ViewportProps, action: ViewportAction) => {
  switch (action.type) {
    case "update":
      return { ...state, ...action.payload };
    case "zoom-to-bounds":
      if (!state.width || !state.height) {
        return state;
      } else if (state.width * state.height === 0) {
        return state;
      } else if (action.payload.geom.features.length === 0) {
        return state;
      } else {
        const newViewport = new WebMercatorViewport({
          ...state,
          width: state.width ?? 100,
          height: state.height ?? 100,
        });
        const [minLng, minLat, maxLng, maxLat] = bbox(action.payload.geom);
        const { longitude, latitude, zoom } = newViewport.fitBounds(
          [
            [minLng, minLat],
            [maxLng, maxLat],
          ],
          {
            padding: {
              top: 50,
              left: window.screen.width < 600 ? 50 : 450, // sm breakpoint is 600px, width of drawer 400px
              right: 50,
              bottom: 50,
            },
          }
        );
        return {
          ...state,
          longitude: longitude,
          latitude: latitude,
          zoom: zoom,
          transitionInterpolator: action.payload.animate
            ? new FlyToInterpolator()
            : undefined,
          transitionDuration: 1000,
        };
      }
    default:
      return state;
  }
};

interface MapViewProps {
  mapRef: MutableRefObject<MapRef | null>;
}

export const MapView = ({ mapRef }: MapViewProps) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const mapContainer = useRef<HTMLDivElement | null>(null);
  const [mapColorDM, setMapColorDM] = useState<string>("");
  const [hoveredFieldId, setHoveredFieldId] = useState<string>();
  const [tooltipVisible, setTooltipVisible] = useState<boolean>(false);
  const [tooltipOverMarker, setTooltipOverMarker] = useState<boolean>(false);

  const [viewport, viewportDispatch] = useReducer(
    viewportReducer,
    initialViewport
  );
  const [height, width] = useHtmlElementSize(mapContainer.current);
  const loginMatch = useRouteMatch("/login");
  const farmid = useAppSelector((state) => state.farms.currentFarmId);

  const fieldMatch = useRouteMatch<MatchProps>("/field/:id");
  const editMatch = useRouteMatch<MatchProps>({
    path: "/edit-field/:id",
    exact: false,
  });

  const addFieldMatch = useRouteMatch<MatchProps>({
    path: "/add-field/",
    exact: false,
  });

  const initialScreenSelectMatch = useRouteMatch<MatchProps>({
    path: "/initial-screen-select",
    exact: false,
  });

  const inputAndOutputScreenMatch = useRouteMatch<MatchProps>({
    path: "/inputs-and-outputs",
    exact: false,
  });

  const reseedScreenMatch = useRouteMatch<MatchProps>({
    path: "/grass-types-reseeding-detail",
    exact: false,
  });

  const addFarmMatch = useRouteMatch<MatchProps>("/add-farm");

  const zoomToGeom = useAppSelector((state) => state.map.zoomToGeom);
  const isDraggingAnimalMarker = useAppSelector(
    (state) => state.map.isDraggingAnimalMarker
  );
  const loggedIn = useAppSelector((state) => state.app.loggedIn);
  const postcodeLocation = useAppSelector(
    (state) => state.map.postcodeLocation
  );
  const navigatorLocation = useAppSelector(
    (state) => state.map.navigatorLocation
  );
  const fieldsWithNoTiles = useAppSelector(getFieldsWithNoTiles);
  const arableFieldsWithNoTiles = useAppSelector(getFieldsWithNoTiles);
  const interactiveLayerIds = ["field-fill", "arable-field-fill"];
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const maxZoom = isMobile ? 19 : 17;

  // We do not want to fly to the geometry the first time,
  // otherwise the map will request tiles on all zoom levels.
  const initialized = useRef(false);

  const isSyncing = useRef(false);

  useEffect(() => {
    viewportDispatch({
      type: "update",
      payload: { height: height, width: width },
    });
  }, [height, width]);

  useEffect(() => {
    if (zoomToGeom) {
      flushSync(() => {
        viewportDispatch({
          type: "zoom-to-bounds",
          payload: { geom: zoomToGeom, animate: initialized.current },
        });
      });

      initialized.current = true;
    }
  }, [zoomToGeom]);

  useEffect(() => {
    // searching for a farm postcode - always active
    if (postcodeLocation) {
      flushSync(() => {
        viewportDispatch({
          type: "update",
          payload: {
            latitude: postcodeLocation.latitude,
            longitude: postcodeLocation.longitude,
            zoom: 14,
          },
        });
      });
    }
  }, [postcodeLocation]);

  useEffect(() => {
    // searching for a farm
    if (!farmid && navigatorLocation) {
      flushSync(() => {
        // only when there are no farms created, otherwise using only postcode based location
        viewportDispatch({
          type: "update",
          payload: {
            latitude: navigatorLocation.latitude,
            longitude: navigatorLocation.longitude,
            zoom: 10,
          },
        });
      });
    }
  }, [navigatorLocation, farmid]);

  const handleMapHover = useCallback(
    (e: MapEvent) => {
      const currentMap = mapRef.current?.getMap();
      if (
        currentMap &&
        e.features &&
        e.features.length > 0 &&
        !isDraggingAnimalMarker
      ) {
        const id: string = e.features[0].properties!.id as string;
        if (
          fieldsWithNoTiles.includes(id) ||
          arableFieldsWithNoTiles.includes(id)
        ) {
          return;
        }

        setHoveredFieldId(id);

        const canvas = currentMap.getCanvas();
        const gl = canvas.getContext("webgl") || canvas.getContext("webgl2");
        if (gl) {
          const { point } = e;
          const [x, y] = point;
          const data = new Uint8Array(4);

          // this adds support for screens with high pixel density
          const devicePixelRatio = window.devicePixelRatio || 1;
          const canvasX = (x - canvas.offsetLeft) * devicePixelRatio;
          const canvasY =
            canvas.height - (y - canvas.offsetTop) * devicePixelRatio;
          gl.readPixels(
            canvasX,
            canvasY,
            1,
            1,
            gl.RGBA,
            gl.UNSIGNED_BYTE,
            data
          );

          const [r, g, b] = data;

          let dm = colorToDM({ r: r, g: g, b: b });
          if (e.features[0].properties!.dryMatterNow < 0) {
            dm = -1;
          }
          setMapColorDM(dm.toString());
          setTooltipVisible(!tooltipOverMarker);
        }
      } else {
        setTooltipVisible(false);
      }
    },
    [
      mapRef,
      fieldsWithNoTiles,
      isDraggingAnimalMarker,
      tooltipOverMarker,
      arableFieldsWithNoTiles,
    ]
  );

  const handleMapClick = useCallback(
    (e: MapEvent) => {
      if (
        !editMatch &&
        !addFieldMatch &&
        !addFarmMatch &&
        !inputAndOutputScreenMatch &&
        !reseedScreenMatch
      ) {
        // when we are not in edit mode, clicking on a field polygon selects a feature
        if (e.features && e.features[0]) {
          // Prevent double-firing on touch devices
          if (e.srcEvent && e.srcEvent.type === "touchend") {
            e.srcEvent.preventDefault();
          }
          // On mobile, use push to maintain history for back navigation
          // On web, use replace since the drawer is always visible
          const fieldPath = `/field/${e.features[0].properties.id}?from=map`;
          if (isMobile) {
            history.push(fieldPath);
          } else {
            history.replace(fieldPath);
          }
          dispatch(openDrawer());
        } else {
          if (isMobile) {
            history.push(`/farm/${farmid}`);
          } else {
            history.replace(`/farm/${farmid}`);
          }
        }
      }
    },
    [
      editMatch,
      addFieldMatch,
      addFarmMatch,
      inputAndOutputScreenMatch,
      reseedScreenMatch,
      farmid,
      history,
      dispatch,
      isMobile,
    ]
  );

  // NOTE: this is ugly but necessary! The map for some reason passes us a
  // previous viewport state here, which means when we resize the viewport,
  // we are not ending up using the latest width and height, resulting in a
  // white border at the right. By overwriting the map viewport below with
  // our own height and width, we make sure to always use the latest
  // viewport dimensions from our useHtmlSize hook.
  const handleViewportUpdate = useCallback(
    (v: ViewportProps, interactionState: any) => {
      const { inTransition, isDragging, isPanning, isRotating, isZooming } =
        interactionState || {};

      if (
        !inTransition &&
        !isDragging &&
        !isPanning &&
        !isRotating &&
        !isZooming
      ) {
        if (height && width) {
          v.height = height;
          v.width = width;
        }
      }

      // For some reason, on Chrome100+ on Pixel 6 the map's internal
      // dimensions are fractional. We never want that, otherwise we
      // end up in an endless render loop where we never just idle.
      if (v.height) {
        v.height = Math.round(v.height);
      }

      if (v.width) {
        v.width = Math.round(v.width);
      }

      // doing sync update only when map is initialized and no other sync update is going on
      if (isSyncing.current || !initialized.current || !mapRef.current) {
        viewportDispatch({ type: "update", payload: v });
      } else {
        isSyncing.current = true;
        flushSync(() => viewportDispatch({ type: "update", payload: v }));
        isSyncing.current = false;
      }
    },
    [height, width]
  );

  const handleViewportUpdateThrottled = useMemo(
    () =>
      isMobile // throttling only on mobile https://github.com/visgl/react-map-gl/issues/1151
        ? throttle(handleViewportUpdate, THROTTLE_MAP_UPDATE_MS)
        : handleViewportUpdate,
    [isMobile, height, width]
  );

  const handleTransformRequest = useCallback((url: string) => {
    // for our own tiles we need to add auth header, for tiles on mapbox we dont
    if (url?.includes(`${process.env.REACT_APP_API_ENDPOINT}`)) {
      return {
        url: url,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt")}`,
          "Content-Type": "application/json",
        },
      };
    } else {
      return {
        url: url,
      };
    }
  }, []);

  if (!loginMatch && loggedIn) {
    // For now we always show the satellite map to the users until we have a better vector base map
    //const vectorMap = "mapbox://styles/robofarmio/ckb96tmya0pyq1inozyuucaia?optimize=true";
    const satelliteMap =
      "mapbox://styles/robofarmio/ckbaq6k7p0rmc1irr9el63loo?optimize=true";

    return (
      <FieldMap ref={mapContainer}>
        <MapGL
          ref={mapRef}
          {...viewport}
          mapOptions={{
            hash: false, // Clashes with HashRouter managing everything after 10.0.2.2:5000/#
          }}
          mapStyle={satelliteMap}
          onViewportChange={handleViewportUpdateThrottled}
          mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
          interactiveLayerIds={interactiveLayerIds}
          onClick={handleMapClick}
          onTouchEnd={handleMapClick}
          onHover={handleMapHover}
          dragRotate={false}
          // min and max values need to be here, otherwise they are not respected in viewport updates
          maxZoom={maxZoom}
          // allow a lower minZoom when adding a farm, in case farms a farther away from eachother
          // or geolocation of user is inaccurate/farther away from the actual farm site.
          minZoom={addFarmMatch ? 1 : 12}
          maxPitch={0}
          minPitch={0}
          reuseMaps
          preserveDrawingBuffer={true} // need this to get the RGBA value below pixel
          transformRequest={handleTransformRequest}
        >
          <MapCursorTooltip
            fieldId={hoveredFieldId}
            dm={mapColorDM}
            visible={tooltipVisible}
          />
          <NavControl
            // onViewportChange={handleViewportUpdate}
            showCompass={false}
            style={{ position: "fixed" }}
          />
          {(editMatch || addFieldMatch) && <MapEditor />}
          <FieldGeoJSONLayers
            setTooltipOverMarker={setTooltipOverMarker}
            fieldId={fieldMatch?.params.id}
            mapRef={mapRef}
            editedFieldId={editMatch?.params.id}
          />
          <ArableFieldGeoJSONLayers
            setTooltipOverMarker={setTooltipOverMarker}
            fieldId={fieldMatch?.params.id}
            mapRef={mapRef}
            editedFieldId={editMatch?.params.id}
          />
          <FieldTileLayers editedFieldId={editMatch?.params.id} />
        </MapGL>
      </FieldMap>
    );
  } else {
    return <FieldMap ref={mapContainer}></FieldMap>;
  }
};

const FieldMap = styled.div(
  ({ theme }) => `
  height: 100vh;
  position: relative;
  z-index: ${theme.zIndex.drawer - 1};
  data-source="map-container";
`
);

const NavControl = styled(NavigationControl)(
  ({ theme }) => `
  position: fixed;
  bottom: ${theme.spacing(5)};
  right: ${theme.spacing(2)};
  :hover {
    background: ${theme.palette.background.default} 
  };
  ${theme.breakpoints.down("sm")} {
    bottom: ${theme.spacing(20)};
  }
`
);
