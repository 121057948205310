import {
  useMediaQuery,
  useTheme,
  AppBar,
  Button,
  Container,
  Dialog,
  IconButton,
  List,
  Popover,
  Toolbar,
  Typography,
  styled,
} from "@mui/material";
import { HelpOutline } from "@mui/icons-material";
import { useTranslation } from "common/locales";
import { useState, MouseEvent } from "react";
import {
  useAppDispatch,
  useAppSelector,
  closeProposedEventsModal,
  selectAllFields,
  selectAllProposedEventsForOneWeekSorted,
} from "model";
import FieldProposedEventListItem from "./FieldProposedEventListItem";
import { mixpanel } from "common/analytics";

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    [theme.breakpoints.up("sm")]: {
      minWidth: "450px",
    },
  },
}));

export const StyledAppBar = styled(AppBar)({
  position: "relative",
});

export const Headline = styled(Typography)(({ theme }) => ({
  flex: 1,
  marginLeft: theme.spacing(2),
}));

export const StyledContainer = styled(Container)(({ theme }) => ({
  maxWidth: 450,
  padding: `${theme.spacing(4)} ${theme.spacing(4)} ${theme.spacing(
    2
  )} ${theme.spacing(4)}`,
}));

export const DescriptionTextContainer = styled(Container)(({ theme }) => ({
  padding: `${theme.spacing(4)} ${theme.spacing(2)} ${theme.spacing(
    4
  )} ${theme.spacing(4)}`,
  maxWidth: 450,
}));

export function FieldProposedEventsModal() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const open = useAppSelector((state) => state.app.proposedEventsModalOpen);
  const loggedIn = useAppSelector((state) => state.app.loggedIn);

  const events = useAppSelector(selectAllProposedEventsForOneWeekSorted);
  const fields = useAppSelector(selectAllFields);

  const canBeShown = loggedIn && open && events.length > 0;
  const handleClose = () => {
    if (open) {
      dispatch(closeProposedEventsModal());
      mixpanel.track("Proposed events modal closed");
    }
  };

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleHelpClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleHelpClose = () => {
    setAnchorEl(null);
  };

  const helpOpen = Boolean(anchorEl);
  const id = helpOpen ? "grazing-detector-help" : undefined;

  return (
    <StyledDialog
      open={canBeShown}
      onClose={handleClose}
      scroll="paper"
      fullScreen={isMobile}
    >
      <StyledAppBar>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleHelpClick}
            aria-label={t("fieldProposedEventsModal.close")}
            size="large"
          >
            <HelpOutline />
          </IconButton>
          <Headline variant="h6">
            {t("fieldProposedEventsModal.headline")}
          </Headline>
          <Button autoFocus color="inherit" onClick={handleClose}>
            {t("fieldProposedEventsModal.close")}
          </Button>
        </Toolbar>
      </StyledAppBar>
      <Popover
        id={id}
        open={helpOpen}
        anchorEl={anchorEl}
        onClose={handleHelpClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <StyledContainer onClick={handleHelpClose}>
          <Typography paragraph>
            {t("fieldProposedEventsModal.description1")}
          </Typography>
          <Typography paragraph>
            {t("fieldProposedEventsModal.description2")}
          </Typography>
          <Typography paragraph>
            {t("fieldProposedEventsModal.description3")}
          </Typography>
          <Typography paragraph>
            {t("fieldProposedEventsModal.description4")}
          </Typography>
        </StyledContainer>
      </Popover>
      <DescriptionTextContainer>
        <Typography>{t("fieldProposedEventsModal.description")}</Typography>
      </DescriptionTextContainer>
      <List subheader={<li />} dense>
        {fields
          .filter((field) => !!field)
          .map((field) => (
            <FieldProposedEventListItem field={field} key={field.guid} />
          ))
          .filter((item) => !!item)}
      </List>
    </StyledDialog>
  );
}

export default FieldProposedEventsModal;
