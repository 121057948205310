import AddIcon from "@mui/icons-material/Add";
import {
  Button,
  Container,
  Divider,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import { unwrapResult } from "@reduxjs/toolkit";
import { useNotification } from "app/NotificationProvider";
import { ConfirmCancellationDialog } from "common/components";
import { AnimalGroupWeightIcon, GrassIcon } from "common/icons";
import { useTranslation } from "common/locales";
import { addMonths } from "date-fns";
import { FieldArray, Form, Formik } from "formik";
import { cloneDeep } from "lodash";
import {
  AnimalGroup,
  createAnimalGroup,
  createStockClass,
  deleteStockClass,
  fetchstockClassesById,
  getDemandForFeedAllocations,
  regenerateFeedingAllocation,
  selectAnimalGroupById,
  updateAnimalGroup,
  updateStockClass,
  useAppDispatch,
  useAppSelector,
} from "model";
import {
  DEFAULT_STOCK_CLASS_NAME,
  DefaultStockClass,
  DefaultStockClasses,
} from "model/defaultStockClassesSlice";
import { RootState } from "model/rootReducer";
import {
  StockClassBirthingPeriod,
  StockClassWithBirthingPeriods,
  createStockClassBirthingPeriod,
  deleteStockClassBirthingPeriod,
  selectStockClassBirthingPeriodsForAnimalGroup,
  selectStockClassWithBirthingPeriodsForAnimalGroup,
  updateStockClassBirthingPeriod,
} from "model/stockClassBirthingPeriodSlices";
import {
  deleteStockClassFeedAllocations,
  fetchStockClassFeedAllocationsById,
  selectStockClassFeedAllocationsForAnimalGroup,
} from "model/stockClassFeedAllocationSlice";
import { useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import styled, { css } from "styled-components";
import * as Yup from "yup";
import { mixpanel } from "../../common/analytics";
import { feedBudgetPlannerEnabledForUserAndAnimalGroup } from "../../common/featureToggle";
import RegenerateFeedAllocationDialog from "./RegenerateFeedAllocationDialog";
import StockClassInput from "./StockClassInput";

const GUID_TO_BE_CREATED = "GUID_TO_BE_CREATED";

export default function AnimalGroupInput() {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [
    regenerateFeedAllocationDialogOpen,
    setRegenerateFeedAllocationDialogOpen,
  ] = useState(false);
  const currentFarmId = useAppSelector((state) => state.farms.currentFarmId);
  const loading = useAppSelector((state) => state.animalGroups.loading);

  const { animalGroupId } = useParams<{ animalGroupId: string }>();
  const animalGroup: AnimalGroup | undefined = useAppSelector((state) =>
    selectAnimalGroupById(state, animalGroupId)
  );

  const feedAllocations =
    useAppSelector((state: RootState) =>
      selectStockClassFeedAllocationsForAnimalGroup(state, animalGroupId)
    ) || null;

  const user = useAppSelector((state) => state.user.details);
  const stockClasses = useAppSelector((state) =>
    selectStockClassWithBirthingPeriodsForAnimalGroup(state, animalGroupId)
  );

  const birthingPeriods = useAppSelector((state) =>
    selectStockClassBirthingPeriodsForAnimalGroup(state, animalGroupId)
  );

  const defaultStockClasses: DefaultStockClasses = useAppSelector(
    (state) => state.defaultStockClasses.stockClasses
  );

  const defaultStockClassesWithBirthingAbilityNames = useMemo(
    () =>
      defaultStockClasses.filter((d) => d.birthingAbility).map((d) => d.name),
    [defaultStockClasses]
  );

  const feedBudgetPlannerEnabled =
    feedBudgetPlannerEnabledForUserAndAnimalGroup(user, animalGroup);

  const feedAllocationDemand = useAppSelector((state) =>
    getDemandForFeedAllocations(state, animalGroupId)
  );

  const validNumber = (min = 0) =>
    Yup.number()
      .typeError(t("edit.minNumber.error", { min }))
      .min(min, t("edit.minNumber.error", { min }))
      .required(t("edit.required.error"));

  const AnimalGroupSchema = Yup.object().shape({
    name: Yup.string().required(t("edit.required.error")),
    stockClasses: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required(t("edit.required.error")),
        animalCount: validNumber(1),
        averageAnimalWeightKg: validNumber(1),
        defaultStockClassName: Yup.string(),
        consumptionKgPerDay: Yup.number().when(() => {
          if (feedBudgetPlannerEnabled) {
            return Yup.number().optional(); // hidden by default
          }

          return Yup.number()
            .typeError(t("edit.minNumber.error", { min: 0.1 }))
            .min(0.1, t("edit.minNumber.error", { min: 0.1 }))
            .required();
        }),
        birthingPeriodValues: Yup.array().when(
          ["defaultStockClassName", "animalCount"],
          (values: string[]): Yup.AnySchema => {
            // validate birthing periods only for stock classes that can give birth
            if (
              feedBudgetPlannerEnabled &&
              defaultStockClassesWithBirthingAbilityNames.includes(
                values[0] as DEFAULT_STOCK_CLASS_NAME
              )
            ) {
              return Yup.array()
                .of(
                  Yup.object().shape({
                    dateStart: Yup.date().required(t("edit.required.error")),
                    dateEnd: Yup.date().required(t("edit.required.error")),
                    expectedNumAnimalsWithoutPregnancy: validNumber(0).max(
                      parseInt(values[1], 10),
                      t("edit.maxNumber.error", { max: values[1] })
                    ),
                    expectedNumOffspringPerAnimal: validNumber(1).max(
                      32,
                      t("edit.maxNumber.error", { max: 32 })
                    ),
                  })
                )
                .required(t("edit.required.error"))
                .min(1, t("edit.required.error"));
            }

            return Yup.array();
          }
        ),
      })
    ),
  });

  const { notify } = useNotification();

  const [deletedStockClasses, setDeletedStockClasses] = useState<string[]>([]);

  const newStockClass = {
    name: "",
    guid: GUID_TO_BE_CREATED,
    animalGroup: animalGroup?.guid ?? "",
    animalCount: 0,
    averageAnimalWeightKg: 0,
    consumptionKgPerDay: 0,
    defaultStockClassName: "DAIRY_COW",
    birthingPeriods: [GUID_TO_BE_CREATED],
    birthingPeriodValues: [
      {
        guid: GUID_TO_BE_CREATED,
        stockClassGuid: GUID_TO_BE_CREATED,
        dateStart: new Date().toISOString(),
        dateEnd: new Date().toISOString(),
        expectedNumAnimalsWithoutPregnancy: 0,
        expectedNumOffspringPerAnimal: 0,
      },
    ],
  };

  // If there aren't any stock classes yet,
  // we create a default stock class for the user to adapt

  const [initialStockClassValues, setInitialStockClassValues] = useState<{
    name: string;
    stockClasses: Array<StockClassWithBirthingPeriods>;
  }>({
    name: "",
    stockClasses: [cloneDeep(newStockClass)],
  });

  const nameInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setInitialStockClassValues({
      name: animalGroup?.name ?? "",
      stockClasses:
        stockClasses && stockClasses.length !== 0
          ? stockClasses.map((stockClass) => ({
              ...stockClass,
              birthingPeriodValues:
                stockClass.birthingPeriodValues.length > 0
                  ? stockClass.birthingPeriodValues
                  : cloneDeep(newStockClass.birthingPeriodValues),
            }))
          : [cloneDeep(newStockClass)],
    });
  }, [animalGroup, stockClasses, birthingPeriods]);

  useEffect(() => {
    // Focus the name input on mount
    if (nameInputRef.current) {
      nameInputRef.current.focus();
    }
  }, []);

  const [formStockClassValues, setFormStockClassValues] = useState<{
    name: string;
    stockClasses: Array<StockClassWithBirthingPeriods>;
  }>({
    name: "",
    stockClasses: [cloneDeep(newStockClass)],
  });

  if (loading) {
    return <LinearProgress />;
  }
  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialStockClassValues}
        validationSchema={AnimalGroupSchema}
        validateOnChange={false} // disable validation every field change, otherwise would validate all fields
        validateOnBlur={false} // disable validation every field blur, otherwise would validate all fields
        onSubmit={(values) => {
          if (
            !animalGroup ||
            !feedBudgetPlannerEnabled // not asking for confirmation when creating animal group or if feed budget planner is not enabled
          ) {
            void handleSave(values.name, values.stockClasses);
          } else {
            setFormStockClassValues(values);
            setRegenerateFeedAllocationDialogOpen(true);
          }
        }}
      >
        {({ values, handleChange, setFieldValue, errors, validateField }) => {
          const handleInputChange = async (e: any, fieldName: string) => {
            await (handleChange(e) as any); // async, typings are wrong
            await (validateField(fieldName) as any); // validate only changed field, async, typings are wrong
          };

          const groupWeight = values.stockClasses.reduce((acc, s) => {
            return (acc += s.averageAnimalWeightKg * s.animalCount);
          }, 0);

          let groupDemandInKg = values.stockClasses.reduce((acc, s) => {
            return (acc += s.consumptionKgPerDay * s.animalCount);
          }, 0);

          if (feedBudgetPlannerEnabled) {
            groupDemandInKg = feedAllocationDemand || 0;
          }

          const groupDemandInPercent =
            groupWeight > 0
              ? ((groupDemandInKg / groupWeight) * 100).toFixed(1)
              : 0;

          return (
            <>
              <Container>
                <List>
                  <ListItem>
                    <Field
                      required
                      id="animal-group-input-name"
                      label={t("edit.animalGroupName.label")}
                      inputRef={nameInputRef}
                      value={values.name}
                      helperText={errors.name}
                      error={!!errors.name}
                      fullWidth
                      onChange={(e) => handleInputChange(e, "name")}
                      name={`name`}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <AnimalGroupWeightIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={`${groupWeight} kg`}
                      secondary={t("edit.weight.label")}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <GrassIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={t("animalGroupInput.demand", {
                        groupDemandInKg: Math.round(groupDemandInKg),
                        groupDemandInPercent,
                      })}
                      secondary={t("edit.demand.label")}
                    />
                  </ListItem>
                </List>
              </Container>
              <Container style={{ textAlign: "left" }}>
                <Typography variant="h6">
                  {t("animalGroupInput.stockClasses")}
                </Typography>
              </Container>
              <Container style={{ textAlign: "center" }}>
                <Form id="animal-group-input-form">
                  <FieldArray name="stockClasses">
                    {({ remove, push }) => (
                      <>
                        {values.stockClasses.length > 0 &&
                          values.stockClasses.map((stockClass, index) => (
                            <StockClassInput
                              key={index}
                              handleInputChange={handleInputChange}
                              stockClass={stockClass}
                              deletedStockClasses={deletedStockClasses}
                              onDelete={(guid: string) => {
                                setDeletedStockClasses([
                                  ...deletedStockClasses,
                                  guid,
                                ]);
                              }}
                              remove={remove}
                              setFieldValue={setFieldValue}
                              index={index}
                              feedBudgetPlannerEnabled={
                                feedBudgetPlannerEnabled
                              }
                              errors={errors?.stockClasses?.[index] as any}
                              validateField={validateField}
                            />
                          ))}
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ marginBottom: "16px" }}
                          startIcon={<AddIcon />}
                          onClick={() => push(cloneDeep(newStockClass))}
                        >
                          {t("animalGroupInput.addStockClass")}
                        </Button>
                      </>
                    )}
                  </FieldArray>
                </Form>
              </Container>
            </>
          );
        }}
      </Formik>
      <Divider />
      <ButtonContainer>
        <ButtonGroup>
          <Button color="secondary" onClick={handleCancel}>
            {t("edit.button.cancel")}
          </Button>
          <Button color="primary" type="submit" form="animal-group-input-form">
            {t("edit.button.save")}
          </Button>
        </ButtonGroup>
        <ConfirmCancellationDialog
          open={open}
          onClose={handleConfirmationClose}
        />
        <RegenerateFeedAllocationDialog
          open={regenerateFeedAllocationDialogOpen}
          onCancel={() => setRegenerateFeedAllocationDialogOpen(false)}
          onConfirm={() => {
            setRegenerateFeedAllocationDialogOpen(false);
            return handleSave(
              formStockClassValues.name,
              formStockClassValues.stockClasses
            );
          }}
        />
      </ButtonContainer>
    </>
  );

  function handleConfirmationClose() {
    setOpen(false);
  }

  function handleCancel() {
    setOpen(true);
  }

  function createUpdateDeleteBirthingPeriods(
    birthingPeriodValues: Array<StockClassBirthingPeriod>,
    stockClassGuid: string,
    selectedDefaultStockClass: DefaultStockClass
  ) {
    if (selectedDefaultStockClass.birthingAbility) {
      return Promise.all(
        birthingPeriodValues.map((birthingPeriod) => {
          if (birthingPeriod.guid === GUID_TO_BE_CREATED) {
            const { guid, ...payload } = { ...birthingPeriod, stockClassGuid }; // removing guid from stock class, not required when creating
            return dispatch(createStockClassBirthingPeriod(payload as any));
          } else {
            const { stockClassGuid, ...payload } = { ...birthingPeriod }; // removing stockClassGuid from stock class, not required when updating
            return dispatch(updateStockClassBirthingPeriod(payload as any));
          }
        })
      );
    } else {
      // otherwise delete all because selected stock class without birthing ability
      return Promise.all(
        birthingPeriodValues.map((birthingPeriod) => {
          if (birthingPeriod.guid !== GUID_TO_BE_CREATED) {
            return dispatch(
              deleteStockClassBirthingPeriod(birthingPeriod.guid)
            );
          }
          return Promise.resolve();
        })
      );
    }
  }

  async function handleSave(
    name: string,
    stockClasses: Array<StockClassWithBirthingPeriods>
  ) {
    try {
      if (animalGroup) {
        await Promise.all(
          deletedStockClasses
            .filter((sc) => sc !== GUID_TO_BE_CREATED)
            .map((guid) => {
              return dispatch(deleteStockClass(guid)).then(unwrapResult);
            })
        );

        // TODO: in MVP we support only single birthing period per stock class, therefore no need for deletion of birthing periods! Only when changing to default stock class without birthing ability

        // first create/update stock classes, then birthing periods
        await Promise.all(
          stockClasses.map(async (s) => {
            let stockClassGuid = s.guid;
            const selectedDefaultStockClass = defaultStockClasses.find(
              (d) => d.name === s.defaultStockClassName
            );
            if (s.guid === GUID_TO_BE_CREATED) {
              const stockClass = await dispatch(
                createStockClass({ ...s, animalGroup: animalGroup.guid })
              ).then(unwrapResult);

              stockClassGuid = stockClass.guid;
            } else {
              await dispatch(updateStockClass(s));
            }
            if (feedBudgetPlannerEnabled) {
              await createUpdateDeleteBirthingPeriods(
                s.birthingPeriodValues,
                stockClassGuid,
                selectedDefaultStockClass!
              );

              s.birthingPeriodValues.sort(
                (bp1, bp2) =>
                  new Date(bp1.dateEnd).getTime() -
                  new Date(bp2.dateEnd).getTime()
              );

              const lastBirthingPeriod = s.birthingPeriodValues.at(-1);

              const newFeedAllocations = await dispatch(
                regenerateFeedingAllocation({
                  stockClassGuid,
                  dateStart: new Date(),
                  dateEnd: addMonths(
                    new Date(lastBirthingPeriod?.dateEnd || new Date()),
                    6
                  ),
                })
              ).then(unwrapResult);
              // removing all feed allocations for this stock class, because regenerate will delete most of those

              dispatch(
                deleteStockClassFeedAllocations(
                  feedAllocations.map((f) => f.guid)
                )
              );
            }
          })
        );

        const updatedAnimalGroup = await dispatch(
          updateAnimalGroup({ ...animalGroup, name })
        ).then(unwrapResult);

        if (feedBudgetPlannerEnabled) {
          const updatedStockClasses = await dispatch(
            // reload stock classes
            fetchstockClassesById(updatedAnimalGroup.stockClasses)
          ).then(unwrapResult);

          await dispatch(
            fetchStockClassFeedAllocationsById(
              // reload all feed allocations
              updatedStockClasses.flatMap((sc) => sc.feedAllocations || [])
            )
          );
          notify(
            t("animalGroupInput.feedAllocation.edit.success", {
              animalGroupName: name,
            })
          );
        } else {
          notify(t("animalGroupInput.edit.success", { animalGroupName: name }));
        }
        mixpanel.track("AnimalGroup edited");
        history.push(`/animal-group/${animalGroup?.guid}`);
      } else if (currentFarmId) {
        const newAnimalGroup = await dispatch(
          createAnimalGroup({
            guid: GUID_TO_BE_CREATED,
            farm: currentFarmId,
            name,
            postGrazingTarget: 1500,
            rotationLength: 20,
            stockClasses: [],
            feedBudgetPlannerEnabled,
          })
        ).then(unwrapResult);
        await Promise.all(
          stockClasses.map(async (sc) => {
            const stockClass = await dispatch(
              createStockClass({ ...sc, animalGroup: newAnimalGroup.guid })
            ).then(unwrapResult);
            const selectedDefaultStockClass = defaultStockClasses.find(
              (d) => d.name === sc.defaultStockClassName
            );
            if (
              feedBudgetPlannerEnabled &&
              sc.birthingPeriodValues.length > 0
            ) {
              await createUpdateDeleteBirthingPeriods(
                sc.birthingPeriodValues,
                stockClass.guid,
                selectedDefaultStockClass!
              );

              sc.birthingPeriodValues.sort(
                (bp1, bp2) =>
                  new Date(bp1.dateEnd).getTime() -
                  new Date(bp2.dateEnd).getTime()
              );

              const lastBirthingPeriod = sc.birthingPeriodValues.at(-1);

              const newFeedAllocations = await dispatch(
                regenerateFeedingAllocation({
                  stockClassGuid: stockClass.guid,
                  dateStart: new Date(),
                  dateEnd: addMonths(new Date(lastBirthingPeriod!.dateEnd), 6),
                })
              ).then(unwrapResult);

              const newGuids = newFeedAllocations.feedingAllocations.map(
                (fa) => fa.guid
              );
              await dispatch(fetchStockClassFeedAllocationsById(newGuids));
            }
          })
        );

        // generate feed allocation once

        mixpanel.track("AnimalGroupAdd successful");
        if (feedBudgetPlannerEnabled) {
          notify(
            t("animalGroupInput.feedAllocation.create.success", {
              animalGroupName: name,
            })
          );
        } else {
          notify(t("animalGroupInput.add.success", { animalGroupName: name }));
        }

        history.push(`/animal-group/${newAnimalGroup.guid}`);
      }
    } catch (e) {
      notify(t("animalGroupInput.boundary.error"), "error");
    }
  }
}

const ButtonGroup = styled.div(
  ({ theme }) => css`
    position: absolute;
    right: ${theme.spacing(2)};
    padding-top: ${theme.spacing(2)};
    padding-bottom: ${theme.spacing(2)};
  `
);

const ButtonContainer = styled(Container)`
  position: relative;
  flex: 0 0 auto;
  display: block;
  height: 70px;
  width: 100%;
  background: white;
`;

const Field = styled(TextField)(
  ({ theme }) => css`
    min-width: 300px;
    padding-bottom: ${theme.spacing(2)};
  `
);
