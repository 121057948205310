import {
  EntityState,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { API } from "common/utils";
import { RootState } from "./rootReducer";

export type FarmerImageUpload = {
  guid: string;
  image: string;
  imageName: string;
};

export type ImageUploadPayload = {
  imageName: string;
  image: string;
};

class FarmerImageUploadAPI extends API<FarmerImageUpload> {
  async uploadImage(payload: ImageUploadPayload): Promise<{ guid: string }> {
    const res = await fetch(`${this.baseUrl}farmerImageUpload`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt") || ""}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    if (!res.ok) {
      throw new Error(`HTTP error! Status: ${res.status}`);
    }

    return (await res.json()) as { guid: string };
  }
}

const farmerImageUploadApi = new FarmerImageUploadAPI("FarmerImageUpload");

export const getSingleFarmerImageUpload = createAsyncThunk<
  Promise<any>,
  { guid: string }
>("farmerImageUpload/getSingle", async ({ guid }: { guid: string }) => {
  return await farmerImageUploadApi.getOne(guid);
});

export const uploadFarmerImage = createAsyncThunk<string, ImageUploadPayload>(
  "farmerImageUpload/uploadImage",
  async (payload: ImageUploadPayload) => {
    const response = await farmerImageUploadApi.uploadImage(payload);
    return response.guid;
  }
);

const farmerImageUploadAdapter = createEntityAdapter<FarmerImageUpload>({
  // we need this because IDs are stored in a field other than `field.id`
  selectId: (farmerImageUpload: FarmerImageUpload) => farmerImageUpload.guid,
  // Keeps the array sorted by guid
  sortComparer: (a: FarmerImageUpload, b: FarmerImageUpload) =>
    a.guid.localeCompare(b.guid),
});

export const {
  selectById: selectFarmerImageUploadById,
  selectAll: selectAllFArmerImageUploads,
} = farmerImageUploadAdapter.getSelectors(
  (state: RootState) => state.farmerImageUploads
);

const initialFarmerImageUploadState: EntityState<FarmerImageUpload> =
  farmerImageUploadAdapter.getInitialState({});

const farmerImageUploadSlice = createSlice({
  name: "farmerImageUpload",
  initialState: initialFarmerImageUploadState,
  reducers: {},
  extraReducers: {
    [getSingleFarmerImageUpload.fulfilled.type]: (state, { payload }) => {
      farmerImageUploadAdapter.upsertOne(state, payload);
    },
    [uploadFarmerImage.fulfilled.type]: (state, { payload }) => {
      // We don't need to update the state here since we're just returning the guid
      // and not the full image object
    },
  },
});

export const farmerImageUploadReducer = farmerImageUploadSlice.reducer;
