import { Menu, MenuItem, PopoverPosition } from "@mui/material";
import { useTranslation } from "common/locales";
import { DiaryEntry } from "model";

interface Props {
  diaryEntry: DiaryEntry;
  anchor: HTMLElement | null;
  anchorPosition?: PopoverPosition;
  closeMenu: () => void;
  openDialog: (openState: boolean) => void;
  canEdit: boolean;
}
export default function DiaryEntryMoreMenu({
  diaryEntry,
  anchor,
  anchorPosition,
  closeMenu,
  openDialog,
  canEdit,
}: Props) {
  const { t } = useTranslation();

  return (
    <Menu
      anchorEl={anchor}
      anchorPosition={anchorPosition}
      anchorReference={
        anchor ? "anchorEl" : anchorPosition ? "anchorPosition" : "none"
      }
      open={anchor ? anchor.id === diaryEntry.guid : false}
      key={`fe-menu-${diaryEntry.guid}`}
      onClose={closeMenu}
    >
      <MenuItem
        key={`fe-menuitem-edit-${diaryEntry.guid}`}
        onClick={() => {
          openDialog(true);
          closeMenu();
        }}
      >
        {canEdit ? t("diaryEntry.view.delete.menu") : t("diaryEntry.view.menu")}
      </MenuItem>
    </Menu>
  );
}
