import { LinearProgress } from "@mui/material";
import { restoreToken } from "common/utils/token";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { mixpanel } from "../../common/analytics";

import { useFormik } from "formik";

import { unwrapResult } from "@reduxjs/toolkit";
import { FullScreenDialog } from "common/components";
import { useTranslation } from "common/locales";
import {
  createUser,
  fetchToken,
  initializeApp,
  initializeFarm,
  useAppDispatch,
  useAppSelector,
} from "model";
import { useEffect, useState } from "react";
import styled from "styled-components";
import UserDetails from "./UserDetailsFromEmail";
import Welcome from "./Welcome";

export interface FormValues {
  mail: string;
  password: string;
  confirmPassword: string;
}

export interface FormErrors {
  confirmPassword?: string | undefined;
}

export default function SignupDialog() {
  const history = useHistory();
  const location = useLocation();
  const { path } = useRouteMatch();
  const dispatch = useAppDispatch();
  const creatingUser = useAppSelector((state) => state.user.loading);
  const appInitialising = useAppSelector((state) => state.app.loading);

  const loading = creatingUser === "pending" || appInitialising === "pending";
  const params = new URLSearchParams(location.search);

  const storedEmail = window.localStorage.getItem("signupEmail") || "";

  async function tryLoadAppWithToken() {
    try {
      const farms = await dispatch(initializeApp()).then(unwrapResult);
      if (farms && farms.length > 0) {
        const defaultFarm = farms[0];
        await dispatch(initializeFarm(defaultFarm));
        history.push(`/farm/${defaultFarm.guid}`);
      } else {
        history.push("/add-farm/enter-name");
      }
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    if (restoreToken(location)) {
      void tryLoadAppWithToken(); // if the user has a valid token, we try to load the app instead of sign up screen
    }
  }, []);

  const { t } = useTranslation();
  const [signupError, setSignupError] = useState<boolean>(false);
  const formik = useFormik<FormValues>({
    initialValues: {
      mail: params.get("mail") || storedEmail || "",
      password: "",
      confirmPassword: "",
    },
    onSubmit: () => {
      console.log("submitted");
      void handleSignup();
    },
    validate: (values) => {
      setSignupError(false);
      const errors: FormErrors = {};
      if (values.password !== values.confirmPassword) {
        errors.confirmPassword = t("signup.confirmPassword.error");
      }
      return errors;
    },
  });
  // even if we don't have the language supported yet,
  // we still want people to be able to save their prefered language.
  // That way we know which missing translations we need to add.
  const language = navigator.language;

  restoreToken(location); // for now trying to restore token from url only on signup

  const handleSignup = async () => {
    mixpanel.track("Signup submitted with user details from email");

    try {
      await dispatch(
        createUser({
          guid: "new",
          farms: [],
          mail: formik.values.mail,
          password: formik.values.password,
          lang: language,
        })
      ).then(unwrapResult);

      mixpanel.track("Initial Login attempted with user details from email");
      await dispatch(
        fetchToken({
          mail: formik.values.mail,
          password: formik.values.password,
        })
      ).then(unwrapResult);
      mixpanel.track("Initial Login successful");
      void dispatch(initializeApp());

      if (params.get("from")) {
        history.push(`${params.get("from")}?from=signup`);
      } else {
        history.push("/signup/welcome");
      }
    } catch (err) {
      console.log(err);
      setSignupError(true);
      mixpanel.track("Initial Login failed");
    }
  };

  return (
    <FullScreenDialog>
      {loading && <LinearProgress />}
      <Switch>
        <Route path="/signup" exact>
          <Redirect to="/signup/user-details" />
        </Route>
        <Route exact path={`${path}/welcome`}>
          <Welcome />
        </Route>
        <Route exact path={`${path}/user-details`}>
          <UserDetails
            values={formik.values}
            errors={formik.errors}
            handleChange={formik.handleChange}
            handleSubmit={formik.handleSubmit}
            signupError={signupError}
          />
        </Route>
      </Switch>
    </FullScreenDialog>
  );
}

export const StyledForm = styled.form(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 250px;
  padding-bottom: ${theme.spacing(2)};
`
);
