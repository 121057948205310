import { useState } from "react";
import styled from "styled-components";
import {
  IconButton,
  Dialog,
  DialogContent,
  Divider,
  Input,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  DialogTitle,
  Typography,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import MailIcon from "@mui/icons-material/Mail";
import LockIcon from "@mui/icons-material/Lock";
import EditIcon from "@mui/icons-material/Edit";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import CheckIcon from "@mui/icons-material/Check";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import CloseIcon from "@mui/icons-material/Close";
import ClearIcon from "@mui/icons-material/Clear";
import {
  changePassword,
  fetchUserById,
  removeOauth2Client,
  useAppDispatch,
  useAppSelector,
} from "model";

import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import { useNotification } from "app/NotificationProvider";
import { useTranslation } from "common/locales";
import { mixpanel } from "common/analytics";
import AgriwebbLogoIcon from "common/icons/AgriwebbLogoIcon";
import {
  ExternalProvider,
  externalProviderName,
} from "features/signup/Oauth2RedirectScreen";

export default function ProfileDialog() {
  const user = useAppSelector((state) => state.user.details);
  const [open, setOpen] = useState(false);
  const { formatMessage: f } = useIntl();
  const [editingPassword, setEditingPassword] = useState(false);
  const dispatch = useAppDispatch();
  const [password, setPassword] = useState("");

  const history = useHistory();
  const { notify } = useNotification();
  const { t } = useTranslation();

  const handlePasswordSubmit = async () => {
    if (password) {
      await dispatch(changePassword(password)).then(unwrapResult);
      notify(t("passwordChange.success"));
      setEditingPassword(false);

      mixpanel.track("Password changed");
    }
  };

  const handleLogout = () => {
    localStorage.clear();

    dispatch({ type: "RESET_STORE" }); // see rootReducer

    mixpanel.track("Logout successful");

    // https://developer.mixpanel.com/docs/javascript#call-reset-at-logout
    // https://developer.mixpanel.com/docs/javascript-full-api-reference#section-mixpanel-reset
    mixpanel.reset();

    history.replace("/");
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditingPassword(false);
  };

  const removeIntegration = async (externalProvider: ExternalProvider) => {
    await dispatch(removeOauth2Client(externalProvider)).then(unwrapResult);
    await dispatch(fetchUserById(user!.guid)).then(unwrapResult);
    notify(
      t("oauth2ClientRemove.success", {
        providerName: externalProviderName[externalProvider],
      })
    );
  };

  if (!user) {
    return <></>;
  }

  return (
    <>
      <ProfileIconContainer>
        <ProfileIcon
          onClick={handleClickOpen}
          aria-controls="profile-menu"
          aria-haspopup="true"
        >
          <AccountBoxIcon />
        </ProfileIcon>
      </ProfileIconContainer>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="profile-dialog-title"
      >
        <ProfileDialogTitle id="profile-dialog-title">
          <Typography variant="h6">{f({ id: "settings.title" })}</Typography>
          <CloseButton onClick={handleClose}>
            <CloseIcon />
          </CloseButton>
        </ProfileDialogTitle>
        <DialogContent dividers>
          <Profile>
            <ListItem>
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              <ListItemText
                primary={user.name}
                secondary={f({ id: "settings.name" })}
                data-private
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <MailIcon />
              </ListItemIcon>
              <ListItemText
                primary={user.mail}
                secondary={f({ id: "settings.mail" })}
                data-private
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <LockIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  editingPassword ? (
                    <Input
                      id="input-password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      type="password"
                    />
                  ) : (
                    f({ id: "settings.password" })
                  )
                }
              />
              <ListItemSecondaryAction>
                {editingPassword ? (
                  <IconButton onClick={handlePasswordSubmit} size="large">
                    <CheckIcon />
                  </IconButton>
                ) : (
                  <IconButton
                    edge="end"
                    aria-label="edit-password"
                    onClick={() => setEditingPassword(true)}
                    size="large"
                  >
                    <EditIcon />
                  </IconButton>
                )}
              </ListItemSecondaryAction>
            </ListItem>
            {user.oauth2clients &&
              user.oauth2clients.includes(ExternalProvider.AGRIWEBB) && (
                <ListItem>
                  <ListItemIcon>
                    <AgriwebbLogoIcon />
                  </ListItemIcon>
                  <ListItemText primary={f({ id: "settings.agriwebb" })} />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label={f({ id: "settings.agriwebb.remove" })}
                      onClick={() =>
                        removeIntegration(ExternalProvider.AGRIWEBB)
                      }
                      size="large"
                      title={f({ id: "settings.agriwebb.remove" })}
                    >
                      <ClearIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              )}
          </Profile>
          <Divider />
          <List>
            <ListItem button onClick={handleLogout}>
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText primary={f({ id: "settings.logout" })} />
            </ListItem>
          </List>
        </DialogContent>
      </Dialog>
    </>
  );
}

const ProfileIconContainer = styled.div(
  ({ theme }) => `
  position: absolute;
  right: ${theme.spacing(2)};
  top: ${theme.spacing(2)};
  z-index: ${theme.zIndex.drawer + 1};
  padding-top: env(safe-area-inset-top);
`
);

const ProfileIcon = styled(IconButton)(
  ({ theme }) => `
  background: white;
  :hover {
    background: ${theme.palette.background.default};
  }
`
);

const ProfileDialogTitle = styled(DialogTitle)(
  ({ theme }) => `
  margin: 0;
  padding: ${theme.spacing(2)};
  `
);

const CloseButton = styled(IconButton)(
  ({ theme }) => `
  position: absolute;
  right: ${theme.spacing(1)};
  top: ${theme.spacing(1)};
  `
);

const Profile = styled(List)(
  ({ theme }) => `
  padding-top: 0;
  min-width: 300px;
`
);
