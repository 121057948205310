import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "./rootReducer";

export type MetabaseUrl = {
  url: string;
  visualizationId: string;
};

export enum METABASE_TYPES {
  QUESTION = "QUESTION",
  DASHBOARD = "DASHBOARD",
}

export class MetabaseUrlApi {
  backendUrl: string;

  constructor() {
    this.backendUrl = `${
      process.env.REACT_APP_API_ENDPOINT || "http://localhost:5000"
    }/v1/metabaseUrl`;
  }

  async getOne(
    visualizationId: string,
    farmGuid: string,
    params: { [name: string]: string }
  ): Promise<MetabaseUrl> {
    let paramsString = "";
    for (const key in params) {
      paramsString = `&${key}=${params[key]}`;
    }
    const finalUrl = `${this.backendUrl}?visualizationId=${visualizationId}&farm_guid=${farmGuid}${paramsString}`;

    const res = await fetch(finalUrl, {
      mode: "cors",
      method: "GET",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt") || ""}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "authorization, x-client-info, apikey, content-type",
      },
    });
    return {
      ...(await res.json()),
      visualizationId: visualizationId,
    } as MetabaseUrl;
  }
}

const metabaseUrlApi = new MetabaseUrlApi();

export const getMetabaseUrl = createAsyncThunk(
  "metabaseUrl/get",
  async ({
    visualizationId,
    farmGuid,
    params,
  }: {
    visualizationId: string;
    farmGuid: string;
    params: { [name: string]: string };
  }) => {
    console.log("in async thunk");
    const result = await metabaseUrlApi.getOne(
      visualizationId,
      farmGuid,
      params
    );
    console.log(result);
    return result;
  }
);

export const metabaseUrlAdapter = createEntityAdapter<MetabaseUrl>({
  // we need this because IDs are stored in a field other than `field.id`
  selectId: (metabaseUrl: MetabaseUrl) => metabaseUrl.visualizationId,
  // Keeps the array sorted by guid
  sortComparer: (a: MetabaseUrl, b: MetabaseUrl) =>
    a.visualizationId.localeCompare(b.visualizationId),
});

export const {
  selectAll: selectAllMetabaseUrl,
  selectById: selectMetabaseUrlById,
} = metabaseUrlAdapter.getSelectors(
  (state: RootState) => state.metabaseUrlEntries
);
type Status = "success" | "error" | null;
type LoadingState = "idle" | "pending";
type MetabaseUrlState = EntityState<MetabaseUrl> & {
  loading: LoadingState;
  error: null;
  status: Status;
};

const initialMetabaseUrlState: MetabaseUrlState =
  metabaseUrlAdapter.getInitialState({
    loading: "idle",
    error: null,
    status: null,
  });

const metabaseUrlSlice = createSlice({
  name: "metabaseUrls",
  initialState: initialMetabaseUrlState,
  reducers: {
    resetMetabaseUrls: () => initialMetabaseUrlState,
  },
  extraReducers: {
    [getMetabaseUrl.pending.type]: (state, action) => {
      if (state.loading === "idle") {
        state.loading = "pending";
        state.status = null;
      }
    },
    [getMetabaseUrl.fulfilled.type]: (
      state,
      action: PayloadAction<MetabaseUrl>
    ) => {
      if (state.loading === "pending") {
        metabaseUrlAdapter.upsertOne(state, action.payload);
        state.loading = "idle";
        state.status = "success";
      }
    },
    [getMetabaseUrl.rejected.type]: (state, action) => {
      if (state.loading === "pending") {
        state.loading = "idle";
        state.status = "error";
        state.error = action.error;
      }
    },
  },
});

export const { resetMetabaseUrls } = metabaseUrlSlice.actions;

export const metabaseUrlReducer = metabaseUrlSlice.reducer;
