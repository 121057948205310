import { AddAPhoto } from "@mui/icons-material";
import { Button, List, ListItem, ListItemText, Tooltip } from "@mui/material";
import { SatelliteIcon } from "common/icons";
import { useTranslation } from "common/locales";
import {
  Field,
  ManualDMInputResponse,
  ManualDMInputResponseItem,
  selectAllEventsForField,
  useAppDispatch,
  useAppSelector,
} from "model";
import {
  DiaryEntry,
  getAllDiaryEntriesForFarm,
  selectDiaryEntryEventsOnField,
} from "model/diaryEntrySlice";
import { useState } from "react";
import DiaryEntryListItem from "./DiaryEntryListItem";
import FieldEventListItem from "./FieldEventListItem";
import NewDiaryEntryDialog from "./NewDiaryEntryDialog";

export default function FieldEventList({ field }: { field: Field }) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [diaryEntryOpen, setDiaryEntryOpen] = useState(false);

  const handleDiaryEntryOpen = () => {
    setDiaryEntryOpen(true);
  };

  const handleDiaryEntryClose = (diaryEntryId: string | null) => {
    setDiaryEntryOpen(false);
    if (diaryEntryId) {
      // Directly refresh the diary entries list
      void dispatch(getAllDiaryEntriesForFarm({ farmId: field.farm }));
    }
  };

  const manualDMForField: Array<ManualDMInputResponse> = useAppSelector(
    (state) => selectAllEventsForField(state, field.guid)
  );

  const diaryEntriesForField: Array<DiaryEntry> = useAppSelector((state) =>
    selectDiaryEntryEventsOnField(state, field.guid)
  );

  // see https://stackoverflow.com/questions/51528780/typescript-check-typeof-against-custom-type
  const isDiaryEntry = (x: any): boolean =>
    Object.keys(x).includes("entryDate");
  const isManualDMInputResponse = (x: any): boolean =>
    Object.keys(x).includes("manualDryMatterInput");

  const getDate = (event: ManualDMInputResponse | DiaryEntry): Date => {
    if (isDiaryEntry(event)) {
      return new Date((event as DiaryEntry).entryDate);
    }
    if (isManualDMInputResponse(event)) {
      return new Date(
        getLatestTime((event as ManualDMInputResponse).manualDryMatterInput)
      );
    }
    return new Date(1);
  };

  let allEvents: Array<ManualDMInputResponse | DiaryEntry> = [];

  allEvents = allEvents.concat(manualDMForField).concat(diaryEntriesForField);

  // This will now sort on every render and we did not find out how to fix it, see https://github.com/robofarmio/web-app-frontend/pull/775#discussion_r1502445622
  // we might go back to 2021 methods like "onMount" etc to be able to compare if we find this is too slow
  const sortedEventList = allEvents.sort(
    (
      a: ManualDMInputResponse | DiaryEntry,
      b: ManualDMInputResponse | DiaryEntry
    ) => {
      const dateA: Date = getDate(a);
      const dateB: Date = getDate(b);
      return dateB.valueOf() - dateA.valueOf();
    }
  );

  return (
    <>
      <List>
        <ListItem>
          <ListItemText primary={t("fieldevents.list.title")} />
          <Button
            onClick={handleDiaryEntryOpen}
            style={{ marginRight: "auto", color: "grey" }}
          >
            <AddAPhoto color="inherit" />
          </Button>
        </ListItem>
        {sortedEventList.map((dm: ManualDMInputResponse | DiaryEntry) => {
          if (isManualDMInputResponse(dm)) {
            return (
              <FieldEventListItem
                key={dm.guid}
                dm={dm as ManualDMInputResponse}
                field={field}
              ></FieldEventListItem>
            );
          } else {
            return (
              <DiaryEntryListItem key={dm.guid} diaryEntry={dm as DiaryEntry} />
            );
          }
        })}
      </List>
      <NewDiaryEntryDialog
        isOpen={diaryEntryOpen}
        close={handleDiaryEntryClose}
        fieldGuid={field.guid}
      />
    </>
  );
}

export function AutoDetectedIcon() {
  const { t } = useTranslation();
  return (
    <Tooltip title={t("fieldevent.autodetected.tooltip")}>
      <div>
        <SatelliteIcon />
      </div>
    </Tooltip>
  );
}

function getLatestTime(response: ManualDMInputResponseItem) {
  const { event: e } = response;
  if (e.dateTimeOut) {
    // covers move out and grazing/partial grazing events
    return Date.parse(e.dateTimeOut);
  }

  if (e.dateTimeIn) {
    // covers move in event
    return Date.parse(e.dateTimeIn);
  }

  if (e.dateTimeCut) {
    // silage event
    return Date.parse(e.dateTimeCut);
  }

  return Date.parse(response.dateTimeMeasurementStart); // reading
}
