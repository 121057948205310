import { useState, useRef, useCallback } from "react";
import { Feature } from "@deck.gl-community/editable-layers/src/utils/geojson-types";
import {
  useAppDispatch,
  setTmpFeatures,
  deleteTmpFeature,
  setTentativeFeature,
  setEditorIsInTentativeMode,
  setEditorSelectionType,
  useAppSelector,
} from "model";
import {
  getEditHandleStyle,
  getFeatureStyle,
  MapEditDrawTools,
} from "features/mapLayers";
import { DeleteForever, Delete, Undo } from "@mui/icons-material";
import { EditorCanDelete, EditorSelectionType } from "./enums";
import { RemovableEditor } from "./RemovableEditor";
import { EditingModeOverride } from "./EditingModeOverride";
import FieldBoundaryDeleteConfirmationDialog from "./FieldBoundaryDeleteConfirmationDialog";
import { booleanValid } from "common/utils";
import styled from "styled-components";
import DrawPolygonModeOverride from "./DrawPolygonModeOverride";
import { useMediaQuery, useTheme } from "@mui/material";
import DrawingHelpModal from "./DrawingHelpModal";

export default function MapEditor() {
  const [mode, setMode] = useState<any>(new EditingModeOverride());
  const [selectedHandleIndexes, setSelectedHandleIndexes] = useState<
    Array<number>
  >([]);

  const [helpModalOpen, setHelpModalOpen] = useState<boolean>(false);
  const handleHelpModalClose = useCallback(() => setHelpModalOpen(false), []);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const selectionType = useAppSelector(
    (state) => state.app.editorSelectionType
  );

  const editorIsInTentativeMode = useAppSelector(
    (state) => state.app.editorIsInTentativeMode
  );

  const [tentativePointsCount, setTentativePointsCount] = useState<number>(0);
  const [fieldBoundaryDeleteDialogOpen, setFieldBoundaryDeleteDialogOpen] =
    useState<boolean>(false);

  const editorRef = useRef<RemovableEditor>();

  const features = useAppSelector(
    (state) => state.app.tmpFeatures as Array<Feature>
  );
  const dispatch = useAppDispatch();

  if (editorIsInTentativeMode && !(mode instanceof DrawPolygonModeOverride)) {
    setMode(new DrawPolygonModeOverride(isMobile)); // TODO: doesn't handle resize events
  } else if (
    !editorIsInTentativeMode &&
    mode instanceof DrawPolygonModeOverride
  ) {
    setMode(new EditingModeOverride());
  }

  const isEditing = selectionType !== EditorSelectionType.NONE; // either creating a new field or selected/editing existing one
  const canAddFeature = !features || features.length === 0; // it's possible to have only 1 field boundary per field for now
  const hasFeature = !canAddFeature;

  let canDelete = hasFeature ? EditorCanDelete.FEATURE : EditorCanDelete.NONE; // can delete feature only when not editign
  const editor = editorRef.current;

  if (editor) {
    if (selectionType === EditorSelectionType.FEATURE) {
      canDelete = EditorCanDelete.NONE; // can't delete feature in edit mode
    } else if (selectionType === EditorSelectionType.HANDLES) {
      if (editor.canDeleteSelectedHandles(selectedHandleIndexes)) {
        canDelete = EditorCanDelete.HANDLES;
      } else {
        canDelete = EditorCanDelete.NONE;
      }
    } else if (selectionType === EditorSelectionType.TENTATIVE_POSITION) {
      if (editor.canDeleteLastTentativePosition()) {
        canDelete = EditorCanDelete.TENTATIVE_POSITION;
      } else {
        canDelete = EditorCanDelete.NONE;
      }
    }
  }

  let deleteIcon = <DeleteForever />; // allow to delete feature only when feature not selected
  let deleteTooltip = "map.deletefeature.tooltip";
  if (editorIsInTentativeMode) {
    deleteIcon = <Undo />;
    deleteTooltip = "map.deleteTentativePosition.tooltip";
  } else if (
    selectionType === EditorSelectionType.FEATURE ||
    selectionType === EditorSelectionType.HANDLES
  ) {
    // when feature/handles selected, allow to delete only handles
    deleteIcon = <Delete />;
    deleteTooltip = "map.deleteSelectedHandles.tooltip";
  }

  const selectedFeatureIndex =
    selectionType === EditorSelectionType.FEATURE ||
    selectionType === EditorSelectionType.HANDLES
      ? 0
      : null;

  const onSelect = useCallback(
    (selected: any) => {
      if (editorIsInTentativeMode) {
        // don't need to update anything
        dispatch(setEditorIsInTentativeMode(true));
        dispatch(
          setEditorSelectionType(EditorSelectionType.TENTATIVE_POSITION)
        );
        return;
      }

      if (selected.selectedFeature && selected.selectedFeatureIndex !== -1) {
        if (selected.selectedFeature.geometry.type === "Polygon") {
          // feature selected
          resetSelection(false, EditorSelectionType.FEATURE);
        }

        if (selected.selectedFeature.geometry.type === "Point") {
          // handle selected
          const handleIndexes = selected.selectedEditHandleIndexes;

          setSelectedHandleIndexes(handleIndexes);
          editor?.selectHandles(handleIndexes);
          if (handleIndexes.length > 0) {
            // red and blue handles
            dispatch(setEditorIsInTentativeMode(false));
            dispatch(setEditorSelectionType(EditorSelectionType.HANDLES));
          } else {
            // otherwise it's just one handle selected for dragging (blue), don't change anything
            setTentativePointsCount(0);
          }
        }
      } else {
        // clicked on a map somewhere, deselecting the feature, but we don't allow to deselect the feature in edit mode and do nothing
        // NOP
      }
    },
    [editorIsInTentativeMode, editor]
  );

  const onDelete = () => {
    // NOTE: when the whole feature is selected, we don't allow to delete it, only allow to select handles

    // trying to remove one point
    if (editor) {
      if (selectionType === EditorSelectionType.TENTATIVE_POSITION) {
        editor.deleteLastTentativePosition();
        return;
      } else if (selectionType === EditorSelectionType.HANDLES) {
        editor.deleteSelectedHandles(editor.getSelectedHandleIndexes()); // storing handles in the state, but deleting whatever is in the editor's state
        return;
      }
    }

    // trying to remove whole feature
    if (selectionType === EditorSelectionType.NONE && hasFeature) {
      setFieldBoundaryDeleteDialogOpen(true);
    }
  };

  const onUpdate = useCallback(
    (props: any) => {
      const { data, editType, editContext } = props;
      if (editType === "addFeature") {
        data[editContext.featureIndexes[0]].properties = { guid: "new" };
        dispatch(setTmpFeatures(data));
        dispatch(setTentativeFeature(null)); // reset tentative feature
        resetSelection(false, EditorSelectionType.NONE);
      }

      if (editType === "addTentativePosition") {
        setTentativePointsCount(tentativePointsCount + 1);
        const tentativePolygon = editor?.getTentativePolygon();
        dispatch(setTentativeFeature(tentativePolygon as any));
      }

      if (editType === "removeTentativePosition") {
        setTentativePointsCount(tentativePointsCount - 1);
        const tentativePolygon = editor?.getTentativePolygon();
        dispatch(setTentativeFeature(tentativePolygon as any));
      }

      if (editType === "removeHandle") {
        setSelectedHandleIndexes([]);
        dispatch(setEditorSelectionType(EditorSelectionType.FEATURE));
      }

      if (editType === "updateTentativeFeature") {
        const tentativePolygon = editor?.getTentativePolygon();
        dispatch(setTentativeFeature(tentativePolygon as any));
      }

      if (editType === "onKeyUp") {
        // TODO: hacking this in here for now, in the future wrap the RemovableEditor and add as a props
        if (!editContext?.event?.srcEvent) {
          return;
        }
        const { keyCode } = editContext.event.srcEvent;
        if (
          (keyCode === 8 || keyCode === 46) &&
          (selectionType === EditorSelectionType.HANDLES ||
            selectionType === EditorSelectionType.TENTATIVE_POSITION)
        ) {
          // delete with backspace or delete
          onDelete();
          return;
        }

        if (
          keyCode === 13 && // confirm with enter
          selectionType !== EditorSelectionType.NONE
        ) {
          handleFieldDrawClick();
          return;
        }
      }

      dispatch(setTmpFeatures(data));

      editType === "addFeature" && dispatch(setEditorIsInTentativeMode(false));
    },
    [editor, tentativePointsCount, selectionType]
  );

  const resetSelection = (
    isInTentativeMode = false,
    editorSelectionType: EditorSelectionType = EditorSelectionType.NONE
  ) => {
    dispatch(setEditorIsInTentativeMode(isInTentativeMode));
    dispatch(setEditorSelectionType(editorSelectionType));
    setSelectedHandleIndexes([]); // reset selected handles
    setTentativePointsCount(0);
    editor?._clearEditingState();
  };

  const handleFieldDrawClick = (e?: any) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }

    if (editorIsInTentativeMode) {
      // from the tentative mode switch to the edit mode (also removes current tentative feature)
      if (tentativePointsCount > 0) {
        // if there is a valid polygon, just save it
        const tentativePolygon = editor?.getTentativePolygon();
        if (booleanValid(tentativePolygon as any)) {
          dispatch(setTmpFeatures([tentativePolygon] as any));
          dispatch(setTentativeFeature(null));
          resetSelection(false, EditorSelectionType.NONE);
          dispatch(setEditorIsInTentativeMode(false));
        } else {
          // else ask if user wants to delete
          setFieldBoundaryDeleteDialogOpen(true);
        }
      } else {
        // if no points, we just switch back to editing mode
        handleFieldBoundaryDeleteAction(true);
      }

      return;
    }

    // Then it must be edit mode (selected feature or handles)

    if (canAddFeature) {
      // if no field boundary created yet, then switch to the tentative mode
      resetSelection(true, EditorSelectionType.TENTATIVE_POSITION);
      dispatch(setEditorIsInTentativeMode(true));
      return;
    }

    if (
      selectionType === EditorSelectionType.FEATURE ||
      selectionType === EditorSelectionType.HANDLES
    ) {
      // if a field boundary is selected, then deselect it (i.e. release the "pencil" button)
      resetSelection(false, EditorSelectionType.NONE);
    } else {
      // otherwise it's in the None mode and will select the existing feature (single one)
      resetSelection(false, EditorSelectionType.FEATURE);
    }
  };

  const handleFieldBoundaryDeleteAction = (deleteConfirmed: boolean) => {
    if (deleteConfirmed) {
      if (selectionType === EditorSelectionType.NONE && hasFeature) {
        // remove the feature if we have it
        //TODO: use the right types. the type for Feature for Editor != Feature for the rest of the app
        editor?.deleteFeatures(0);
        dispatch(setTmpFeatures([])); // making use of the fact that we can have only single feature
        dispatch(deleteTmpFeature(features[0].properties?.id));
      }
      // reset the editor
      resetSelection(false, EditorSelectionType.NONE);
      dispatch(setEditorIsInTentativeMode(false));
    }
    setFieldBoundaryDeleteDialogOpen(false);
  };

  const handleHelpClick = useCallback(() => {
    setHelpModalOpen(true);
  }, []);

  return (
    <MapEditorContainer>
      <RemovableEditor
        ref={editorRef as any}
        style={{
          width: "100%",
          height: "100%",
          cursor: editorIsInTentativeMode ? "crosshair" : "inherit",
        }}
        clickRadius={100}
        mode={mode}
        onSelect={onSelect}
        onUpdate={onUpdate}
        editHandleShape={"circle"}
        featureStyle={getFeatureStyle}
        editHandleStyle={getEditHandleStyle}
        selectedFeatureIndex={selectedFeatureIndex}
        features={features}
        featuresDraggable={false}
        selectable={false}
      />
      <MapEditDrawTools
        onHelpClick={handleHelpClick}
        onDelete={onDelete}
        onFieldDrawClick={handleFieldDrawClick}
        canDeleteAnything={canDelete !== EditorCanDelete.NONE}
        isEditing={isEditing}
        deleteIcon={deleteIcon}
        deleteTooltip={deleteTooltip}
      />
      <FieldBoundaryDeleteConfirmationDialog
        open={fieldBoundaryDeleteDialogOpen}
        handleAction={handleFieldBoundaryDeleteAction}
      />
      <DrawingHelpModal
        isOpen={helpModalOpen}
        handleClose={handleHelpModalClose}
      />
    </MapEditorContainer>
  );
}

const MapEditorContainer = styled.div(
  ({ theme }) => `
  width: 100%;
  height: 100%;
`
);
