import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { mixpanel } from "common/analytics";
export type Postcode = {
  code: string;
  formattedCode: string;
  countryCode: string;
  latitude: number;
  longitude: number;
};

export function instanceOfPostcode(object: any): object is Postcode {
  return typeof object === "object" && "code" in object;
}

export type Location = { latitude: number; longitude: number };

export type IPLookup = {
  latitude: number;
  longitude: number;
  country: string;
};

export class GeolocationAPI {
  baseUrl: string;
  constructor() {
    this.baseUrl = `${
      process.env.REACT_APP_API_ENDPOINT || "http://localhost:5000"
    }/v1/`;
  }

  async getPostcodes(params?: URLSearchParams): Promise<Postcode[]> {
    mixpanel.track("Get postcode attempted");
    const res = await fetch(`${this.baseUrl}postcodes?${params}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt") || ""}`,
        "Content-Type": "application/json",
      },
    });

    if (res.status !== 200) {
      mixpanel.track("Get postcode failed");
      throw new Error("Unable to load postcode");
    }
    mixpanel.track("Get postcode successful");
    return (await res.json()) as Postcode[];
  }

  // Note: makes a single geolocation api request,
  // we assume the user doesn't move while using
  // the app here; if this assumption changes we
  // need to use geolocation.watchPosition
  async getNavigatorGeolocation(): Promise<Location | undefined> {
    mixpanel.track("Geolocation attempted");
    if (navigator.geolocation) {
      return new Promise<Location | undefined>((resolve, reject) =>
        navigator.geolocation.getCurrentPosition(
          (pos) => {
            mixpanel.track("Geolocation successful");
            resolve({
              latitude: pos.coords.latitude,
              longitude: pos.coords.longitude,
            });
          },
          (err: any) => {
            mixpanel.track("Geolocation failed");
            reject(err);
          }
        )
      );
    } else {
      mixpanel.track("Geolocation disabled");
      return Promise.reject(new Error("Geolocation disabled"));
    }
  }

  async getIPLocation(): Promise<IPLookup | undefined> {
    mixpanel.track("Get ip location attempted");
    const res = await fetch(`${this.baseUrl}geoip`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt") || ""}`,
        "Content-Type": "application/json",
      },
    });

    if (res.status !== 200) {
      mixpanel.track("Get ip location failed");
      throw new Error(`Unable to load geo ip location`);
    }

    mixpanel.track("Get ip location successful");
    return (await res.json()) as IPLookup;
  }
}

export const geolocationAPI = new GeolocationAPI();

export const fetchPostcodes = createAsyncThunk(
  "postcode/fetchPostcodes",
  async ({ code }: { code: string }) =>
    geolocationAPI.getPostcodes(new URLSearchParams({ code }))
);

export const getNavigatorGeolocation = createAsyncThunk(
  "navigator/getGeolocation",
  async () => geolocationAPI.getNavigatorGeolocation()
);

export const fetchIPLocation = createAsyncThunk(
  "geoip/fetchIPLocation",
  async () => geolocationAPI.getIPLocation()
);

export const selectPostcode = createAction<Postcode>("postcode/selectPostcode");
