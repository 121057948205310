import DropdownInput from "common/components/DropdownInput";
import { styled } from "@mui/material/styles";
import { useTranslation } from "common/locales";
import { selectAllAnimalGroups } from "model";
import { useSelector } from "react-redux";
import {
  Theme,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
const PREFIX = "AssignedToDropdown";

export const classes = {
  formControl: `${PREFIX}-formControl`,
};

export const StyledFormControl = styled(FormControl)(
  ({ theme }: { theme: Theme }) => ({
    [`&.${classes.formControl}`]: {
      minWidth: 300,
      paddingBottom: theme.spacing(2),
    },
  })
);

interface AssignedToDropdownProps {
  assignedTo?: string;
  onChange: (value?: string) => void;
}

export default function AssignedToDropdown({
  assignedTo,
  onChange,
}: AssignedToDropdownProps) {
  const { t } = useTranslation();
  const animalGroups = useSelector(selectAllAnimalGroups);

  // If there is not a single animal group set up yet,
  // i.e. during initial farm setup, we do not want to
  // confuse users by showing the silage group only.
  if (animalGroups.length === 0) return null;

  return (
    <StyledFormControl className={classes.formControl}>
      <InputLabel id="assignedGroup-select-label" variant="standard">
        {t("fieldinput.assignedTo.text")}
      </InputLabel>
      <Select
        labelId="assignedGroup-select-label"
        id="assignedGroup-select"
        value={assignedTo ?? "silage"}
        onChange={onChangeSelect}
        input={<DropdownInput />}
      >
        <MenuItem value="silage" key="silage">
          {t("fieldinput.assignedTo.silage")}
        </MenuItem>
        {animalGroups.map((animalGroup) => (
          <MenuItem value={animalGroup.guid} key={animalGroup.guid}>
            {animalGroup.name}
          </MenuItem>
        ))}
      </Select>
    </StyledFormControl>
  );

  function onChangeSelect(e: any) {
    onChange(e.target.value === "silage" ? undefined : e.target.value);
  }
}
