import {
  Box,
  Button,
  Container,
  Dialog,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { mixpanel } from "common/analytics";
import { useTranslation } from "common/locales";
import {
  closeAfterFieldCreatedDialog,
  getFieldCount,
  openDrawer,
  setIsEditing,
  useAppDispatch,
  useAppSelector,
} from "model";

import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    [theme.breakpoints.up("sm")]: {
      minWidth: "450px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      margin: "16px",
    },
  },
}));

export const Headline = styled(Typography)({
  marginLeft: 0,
  fontWeight: 700,
});

export const CTAButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  fontWeight: 700,
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    marginBottom: theme.spacing(1),
  },
}));

export const HeadlineBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginBottom: theme.spacing(2),
}));

export const StyledRowBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  display: "flex",
  flexDirection: "row",
  alignSelf: "center",
  columnGap: theme.spacing(2),
  padding: theme.spacing(0, 2),
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    width: "100%",
    columnGap: 0,
  },
}));

export const DescriptionTextContainer = styled(Container)(({ theme }) => ({
  padding: `${theme.spacing(4)} ${theme.spacing(2)} ${theme.spacing(
    2
  )} ${theme.spacing(4)}`,
  maxWidth: 450,
}));

export function AfterFieldCreatedDialog() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const open = useAppSelector((state) => state.app.fieldCreatedDialogOpen);
  const fieldCount = useAppSelector(getFieldCount);
  const history = useHistory();

  const handleClose = () => {
    if (open) {
      dispatch(closeAfterFieldCreatedDialog());
      mixpanel.track("AfterFieldCreatedDialog after 1st field closed");
    }
  };

  const handleClickDialog = () => isMobile && handleClose();

  return (
    <StyledDialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      onClick={handleClickDialog}
    >
      <DescriptionTextContainer>
        <>
          {fieldCount == 1 && (
            <HeadlineBox>
              <Headline variant="h6">
                {t("after_first_field_created.field.dialog.title")}
              </Headline>
            </HeadlineBox>
          )}
        </>
        <Typography gutterBottom={true}>
          <>
            {fieldCount == 1 && (
              <FormattedMessage id="after_first_field_created.field.dialog.text" />
            )}
            {fieldCount > 1 && (
              <FormattedMessage id="after_field_created.field.dialog.text" />
            )}
          </>
        </Typography>
      </DescriptionTextContainer>

      <StyledRowBox>
        <CTAButton
          onClick={handleAddFieldClick}
          color="primary"
          variant="contained"
        >
          {t("after_field_created.field.dialog.anotherField")}
        </CTAButton>
        <CTAButton
          onClick={handleAddAnimalGroupClick}
          color="primary"
          variant="contained"
        >
          {t("after_field_created.field.dialog.addAnimalGroup")}
        </CTAButton>
        <CTAButton onClick={handleClose} color="secondary" variant="contained">
          {t("after_field_created.field.dialog.close")}
        </CTAButton>
      </StyledRowBox>
    </StyledDialog>
  );

  function handleAddFieldClick() {
    handleClose();
    dispatch(setIsEditing());
    mixpanel.track("FieldAdd started");
    history.push(`/add-field/enter-details`);
  }

  function handleAddAnimalGroupClick() {
    handleClose();
    dispatch(openDrawer());
    mixpanel.track("AnimalGroupAdd started");
    history.push(`/add-animal-group`);
  }
}

export default AfterFieldCreatedDialog;
