import {
  FeatureCollection,
  ModeProps,
  PointerMoveEvent,
} from "@deck.gl-community/editable-layers";
import { DrawPolygonMode } from "../../react-map-gl-draw";
import { throttle } from "lodash";
import { THROTTLE_MS } from "./const";

type PointerMoveHandler = (
  event: PointerMoveEvent,
  props: ModeProps<FeatureCollection>
) => void;

export default class DrawPolygonModeOverride extends DrawPolygonMode {
  private isMobile = false;
  protected handlePointerMoveThrottled: PointerMoveHandler | null | undefined =
    null;

  constructor(isMobile: boolean, throttleMs = THROTTLE_MS) {
    super();
    this.isMobile = isMobile;
    this.handlePointerMoveThrottled = throttle(
      this.handlePointerMoveAux.bind(this),
      throttleMs
    );
  }

  protected handlePointerMoveAux(
    event: PointerMoveEvent,
    props: ModeProps<FeatureCollection>
  ): void {
    if (!this.isMobile) {
      // prevent lines following the cursor when dragging on mobile devices
      super.handlePointerMove(event, props);
    }
  }

  public handlePointerMove(
    event: PointerMoveEvent,
    props: ModeProps<FeatureCollection>
  ): void {
    try {
      if (this.handlePointerMoveThrottled) {
        this.handlePointerMoveThrottled(event, props);
      }
    } catch (e) {
      console.log("Exception while DrawPolygonModeOverride handlePointerMove");
      console.error(e);
    }
  }

  // Fix to prevent lines following the cursor when dragging on mobile devices
  public createTentativeFeature(props: ModeProps<FeatureCollection>): any {
    if (!this.isMobile) {
      return super.createTentativeFeature(props);
    }
    // the same as super.createTentativeFeature, but not using last cursor position
    const clickSequence = this.getClickSequence();

    let tentativeFeature;
    if (clickSequence.length === 1 || clickSequence.length === 2) {
      tentativeFeature = {
        type: "Feature",
        properties: {
          guideType: "tentative",
        },
        geometry: {
          type: "LineString",
          coordinates: [...clickSequence],
        },
      };
    } else if (clickSequence.length > 2) {
      tentativeFeature = {
        type: "Feature",
        properties: {
          guideType: "tentative",
        },
        geometry: {
          type: "Polygon",
          coordinates: [[...clickSequence, clickSequence[0]]],
        },
      };
    }

    return tentativeFeature;
  }
}
